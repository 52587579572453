import React, {FC} from 'react'
import {IntegrationManagement} from './Integration'
import {Helmet} from 'react-helmet'
import { IntegrationPageTitle } from '../../../_metronic/layout/core/IntegrationPageData'

const IntegrationWrapper: FC = () => {
  return (
    <>
       <Helmet>
        <title>SecureSlate | Integrations</title>
      </Helmet>
      <IntegrationPageTitle
        icon='integrations.svg'
        title='Integrations'
        desc='Connect services and activate automated evidence collection & monitoring.'
        // breadcrumbs={usersBreadcrumbs}
      >
        Integrations
      </IntegrationPageTitle>
      {/* <KTCard> */}
        {/* <IntegrationListHeader /> */}
        <IntegrationManagement className='mb-5 mb-xl-8' />
        {/* <VendorRisk className='card-xl-stretch mb-xl-8' /> */}
      {/* </KTCard> */}
    </>
  )
}

export default IntegrationWrapper
