/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ChatInner} from '../../../_metronic/partials'
import { Helmet } from 'react-helmet';

const ChatGPT: FC = () => {
  return (
    <>
     <Helmet>
        <title>SecureSlate | Chat</title>
      </Helmet>
    <div className='d-flex flex-column flex-lg-row' style={{height: '100%'}}>
      <div className='flex-lg-row-fluid '>
        <div className='card' id='kt_chat_messenger' style={{height: '100%'}}>
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover'>
                {/* <div className='symbol symbol-35px symbol-circle'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-5.jpg')} />
                </div>
                <div className='symbol symbol-35px symbol-circle'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-25.jpg')} />
                </div>
                <div className='symbol symbol-35px symbol-circle'>
                  <span className='symbol-label bg-light-warning text-warning 40px'>M</span>
                </div>
                <div className='symbol symbol-35px symbol-circle'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-9.jpg')} />
                </div>
                <div className='symbol symbol-35px symbol-circle'>
                  <span className='symbol-label bg-light-danger text-danger 40px'>O</span>
                </div> */}
                <div className='symbol symbol-35px symbol-circle'>
                  <span className='symbol-label bg-light-primary text-primary 40px'>AI</span>
                </div>
                {/* <div className='symbol symbol-35px symbol-circle'>
                  <img alt='Pic' src={toAbsoluteUrl('/media/avatars/300-23.jpg')} />
                </div>
                <a
                  href='#'
                  className='symbol symbol-35px symbol-circle'
                  // data-bs-toggle='modal'
                  // data-bs-target='#kt_modal_view_users'
                >
                  <span
                    className='symbol-label fs-8 fw-bolder'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    title='View more users'
                  >
                    +42
                  </span>
                </a> */}
              </div>
            </div>

            <div className='card-toolbar'>
              <div className='me-n3'>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-2'></i>
                </button>
                {/* <Dropdown1 /> */}
              </div>
            </div>
          </div>

          <ChatInner />
        </div>
      </div>
    </div>
    </>
  )
}

export {ChatGPT}
