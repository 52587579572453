/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {DashPageTitle} from '../../../_metronic/layout/core/DashPageData'
import {Helmet} from 'react-helmet'
import DashboardHeader from './components/headers/DashboardHeader'
import DashboardPageHeader from './components/headers/DashboardPageHeader'
import {TaskList} from './components/table/TaskList'
import useTenantCheck from '../../Hooks/useTenantCheck'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useAuth} from '../../modules/auth'

const DashboardPage: FC = () => {
  useTenantCheck()
  const {currentUser} = useAuth()

  return (
    <>
      <Helmet>
        <title>SecureSlate | Dashboard</title>
      </Helmet>
      <ToastContainer />
      {currentUser?.role !== 'Auditor' && (
        <DashPageTitle
          icon='copy-success.svg'
          title='Onboarding'
          desc='Quickly set up your ISMS for streamlined security management'
        ></DashPageTitle>
      )}
      {/* <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
    
      <i className="ki-duotone ki-design-1 fs-2tx text-primary me-4"></i>

    
      <div className="d-flex flex-stack flex-grow-1">
      
        <div className="fw-semibold">
          <div className="fs-6 text-gray-700">
            By connecting an account, you hereby agree to our{' '}
            <a href="#" className="me-1">
              privacy policy
            </a>
            and{' '}
            <a href="#">
              terms of use
            </a>.
          </div>
        </div>
      </div>
    </div> */}
      {/* begin::Row */}
      <DashboardHeader />
      <DashboardPageHeader />

      {/* begin::Row */}
      {currentUser?.role !== 'Auditor' && (
        <div className='row gy-5 gx-xl-12'>
          <div className='col-sm-12 '>
            <TaskList className='card-xxl-stretch mb-4 mb-xl-8' />
          </div>
          {/* <div className='col-sm-4'>
       
          <ActivitiesTable className='card-xxl-stretch mb-4 mb-xl-8'/>
        </div> */}
          {/* <div className='col-xl-4'>
          <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            <CardsWidget21
              className='h-md-40 '
              description='Total Assets'
              // color='#44DBAF'
              // img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            />
            <CardsWidget7
              className='h-md-40 mb-xl-10'
              description='Total Active Personnel'
              icon={false}
              stats={357}
              labelColor='dark'
              textColor='gray-300'
            />
          </div>
        </div> */}
        </div>
      )}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className='row gy-5 g-xl-8'>
        <div className='col-xl-4'>
          <ListsWidget2 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget6 className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-4'>
          <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} /> */}
      {/* partials/widgets/lists/_widget-4', 'className' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
      {/* </div>
      </div> */}
      {/* end::Row */}

      {/* <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-4'>
          <MixedWidget8
            className='card-xxl-stretch mb-xl-3'
            chartColor='success'
            chartHeight='150px'
          />
        </div>
        <div className='col-xxl-8'>
          <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
        </div>
      </div> */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
