import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import {OnDashPageTitle} from '../cores/OnDashPageData'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {Country, ICountry} from 'country-state-city'
import {getDownloadURL, ref, uploadBytesResumable} from '@firebase/storage'
import db, {storage} from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {Tooltip} from 'bootstrap'
import {Helmet} from 'react-helmet'
import {doc, getDoc, setDoc} from 'firebase/firestore'

interface FormData {
  id?: string
  fullName: string
  email: string
  avatarUrl: string | null
  country: string
  currentAddress: string
  countryCode: string
  phoneNumber: string
}

interface CountryModule {
  getCountryByCode: (isoCode: string) => ICountry | undefined
  getAllCountries: () => ICountry[]
  sortByIsoCode: (countries: ICountry[]) => ICountry[]
}
const initialState: FormData = {
  // id:'',
  fullName: '',
  email: '',
  avatarUrl: '',
  country: '',
  currentAddress: '',
  countryCode: '',
  phoneNumber: '',
}
const validationSchema = Yup.object().shape({
  fullName: Yup.string().notRequired(),
  email: Yup.string().email('Invalid email').notRequired(),
  country: Yup.string().required('Country is required'),
  currentAddress: Yup.string().required('Current address is required'),
  phoneNumber: Yup.string().required('phonenumber is required'),
})
const BackgroundCheck = () => {
  const {currentUser} = useAuth()
  const [form, setForm] = useState<FormData>(initialState)
  const {fullName, email, currentAddress, phoneNumber, country, countryCode, avatarUrl} = form
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState<number | null>(null)

  const [message, setMessage] = useState({error: false, msg: ''})
  const [showAlert, setShowAlert] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const [backgroundInfo, setbackgroundInfo] = useState<any>([])

  const [editForm, setEditForm] = useState(false)

  // const userAvatarImg = toAbsoluteUrl('/media/avatars/blank.png')
  const blankImg = toAbsoluteUrl('/media/svg/avatars/blank.svg')

  const countries: CountryModule = Country

  const [selectedCountry, setSelectedCountry] = useState<string>('')
  const [countryCodes, setCountryCodes] = useState<string[]>([])
  // const [editForm, setEditForm] = useState<FormData | null>(null);

  useEffect(() => {
    // Initialize tooltips
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    )
    tooltipTriggerList.map((tooltipTriggerEl: HTMLElement) => new Tooltip(tooltipTriggerEl))

    // Initialize image input
    const imageInput = document.querySelector('.image-input')
    const imageInputWrapper = document.querySelector('.image-input-wrapper')
    imageInput?.addEventListener('change', (e: any) => {
      const file = e.target.files[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = function (e: any) {
          imageInputWrapper?.setAttribute('style', `background-image: url('${e.target.result}');`)
        }
        reader.readAsDataURL(file)
      }
    })
  }, [])

  useEffect(() => {
    // Fetch and set country codes based on the selected country
    if (selectedCountry) {
      const countryData = Country.getCountryByCode(selectedCountry)
      if (countryData) {
        const codes = countryData.phonecode.split(',')
        setCountryCodes(codes)

        // If you want to set a default country code, you can uncomment the line below
        setForm((prevForm) => ({...prevForm, countryCode: codes[0]}))
      }
    }
  }, [selectedCountry])

  useEffect(() => {
    if (currentUser) {
      setForm((prevForm) => ({
        ...prevForm,
        email: currentUser.email || '',
        fullName: currentUser.userName || '',
      }))
    }
  }, [currentUser])

  useEffect(() => {
    const fetchIdentityData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        const userId = currentUser?.uid || ''

        if (!tenantId || !userId) {
          // If either tenantId or userId is not available, exit early
          return
        }

        const tenantRef = doc(db, 'tenants', tenantId)
        const identityDocRef = doc(tenantRef, 'onboarding', 'identity')

        // Fetch the identity document
        const identityDoc = await getDoc(identityDocRef)
        const identityData = identityDoc.exists() ? identityDoc.data() : null

        if (identityData) {
          // Update the form state with identity data
          setForm(identityData.identity as FormData)
          setEditForm(true)
        } else {
          // Initialize form state if identity data doesn't exist
          setForm({
            fullName: '',
            email: '',
            country: '',
            countryCode: '',
            currentAddress: '',
            phoneNumber: '',
            avatarUrl: '',
          })
          setFile(null)
          setEditForm(false) // Set edit mode to false if no data exists
        }
      } catch (error) {
        console.error('Error fetching identity data:', error)
        setEditForm(false) // Set edit mode to false on error
      }
    }

    fetchIdentityData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser?.tenantId, currentUser?.uid])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      if (!fullName || !email) {
        throw new Error('Please fill all the fields')
      }

      setIsLoading(true)

      // Validate form using validationSchema (not shown, assuming it's handled elsewhere)
      // await validationSchema.validate(form, { abortEarly: false });

      let downloadURL: string | null = avatarUrl

      // Upload file to Firebase Storage if a file is provided
      if (file) {
        const fileName = new Date().getTime() + file.name
        const storageRef = ref(storage, `employeeImages/${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
        await uploadTask

        // Get download URL after upload
        downloadURL = await getDownloadURL(uploadTask.snapshot.ref)

        setForm((prevForm) => ({...prevForm, avatarUrl: downloadURL}))
      }

      const tenantId = currentUser?.tenantId || ''

      if (!tenantId) {
        throw new Error('No tenant ID found')
      }

      const tenantRef = doc(db, 'tenants', tenantId)
      const onboardingCollectionRef = doc(tenantRef, 'onboarding', 'identity')

      // Check if the document exists in the "Onboarding" collection
      const onboardingSnapshot = await getDoc(onboardingCollectionRef)
      let onboardingData = onboardingSnapshot.exists() ? onboardingSnapshot.data() : {}

      // Update or set new onboarding data
      const newOnboarding = {
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        fullName,
        email,
        country: selectedCountry || '',
        countryCode,
        currentAddress,
        phoneNumber,
        avatarUrl: downloadURL || avatarUrl,
      }

      const updatedPolicyData = {
        ...onboardingData,
        identity: newOnboarding,
      }

      // Set or update the policy document in Firestore
      await setDoc(onboardingCollectionRef, updatedPolicyData)
      // Show success message
      setMessage({error: false, msg: 'Form submitted successfully!'})
      setShowAlert(true)

      // Redirect to another page after submission
      setTimeout(() => {
        navigate('/backgroundCheck')
      }, 3000)
    } catch (error) {
      console.error('Error submitting form:', error)
      setMessage({error: true, msg: 'An error occurred'})
      setShowAlert(true)
    } finally {
      setIsLoading(false)

      // Clear alert after 3 seconds
      setTimeout(() => {
        setShowAlert(false)
      }, 3000)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0]
      setFile(file)

      // Create a URL for the file and update the form
      const imageUrl = URL.createObjectURL(file)
      setForm((prevForm) => ({...prevForm, avatarUrl: imageUrl}))

      // Update the image display
      const imageWrapper = document.querySelector('.image-input-wrapper') as HTMLElement
      if (imageWrapper) {
        imageWrapper.style.backgroundImage = `url('${imageUrl}')`
      }
    }
  }

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value
    setSelectedCountry(selectedCountry)
    setForm({...form, country: selectedCountry})
  }
  const handleCountryCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountryCode = e.target.value
    const updatedCountryCodes =
      Country.getCountryByCode(selectedCountry)?.phonecode.split(',') || []

    setCountryCodes(updatedCountryCodes)
    setForm({...form, countryCode: selectedCountryCode})
  }
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, phoneNumber: e.target.value})
  }

  return (
    <>
      <Helmet>
        <title>SecureSlate | Background Check</title>
      </Helmet>
      <OnDashPageTitle
        icon='Bacgroundcheck.svg'
        title='Identity Verification'
        desc="Some basic details for your company's records."
      />

      <div className='modal-dialog modal-dialog-centered mw-1111px p-4 rounded bg-white border'>
        <div className='modal-content '>
          <div className='modal-header p-4'>
            <h3 className='modal-title'>Profile Details</h3>
          </div>
          <hr className='my-0' style={{borderTop: '1px solid #F1F1F2'}} />

          {showAlert && (
            <div style={{marginTop: 20, marginLeft: 20, marginRight: 20}}>
              {message?.msg && (
                <div
                  className={
                    message?.error
                      ? 'alert alert-dismissible bg-light-danger d-flex flex-column flex-sm-row'
                      : 'alert alert-dismissible bg-light-success d-flex flex-column flex-sm-row'
                  }
                >
                  {message?.error ? (
                    <i className='ki-duotone ki-notification-bing fs-2hx text-danger me-4 mb-5 mb-sm-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  ) : (
                    <i className='ki-duotone ki-notification-bing fs-2hx text-success me-4 mb-5 mb-sm-0'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                      <span className='path3'></span>
                    </i>
                  )}

                  <div
                    className={
                      message?.error
                        ? 'd-flex flex-column text-danger text-primary text-bold pe-0 pe-sm-10'
                        : 'd-flex flex-column text-success text-primary text-bold pe-0 pe-sm-10'
                    }
                  >
                    {message?.error ? (
                      <h5 className='mb-1'>Error!!</h5>
                    ) : (
                      <h5 className='mb-1'>Sucess!!</h5>
                    )}
                    <span>{message?.msg}</span>
                  </div>
                  <button
                    type='button'
                    className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
                    onClick={() => setShowAlert(false)}
                  >
                    <span className='svg-icon svg-icon-1 svg-icon-success'>
                      <span className='svg-icon svg-icon-2x svg-icon-primary'>
                        <svg
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                          className='mh-50px'
                        >
                          <rect
                            opacity='0.5'
                            x='6'
                            y='17.3137'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(-45 6 17.3137)'
                            fill='black'
                          ></rect>
                          <rect
                            x='7.41422'
                            y='6'
                            width='16'
                            height='2'
                            rx='1'
                            transform='rotate(45 7.41422 6)'
                            fill='black'
                          ></rect>
                        </svg>
                      </span>
                    </span>
                  </button>
                </div>
              )}
            </div>
          )}
          {!editForm ? (
            <form
              id='kt_modal_add_user_form'
              className='form'
              // onSubmit={formik.handleSubmit}
              noValidate
              onSubmit={handleSubmit}
            >
              <div
                className='d-flex flex-column scroll-y me-n7 pe-7'
                id='kt_modal_add_user_scroll'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: false, lg: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_modal_add_user_header'
                data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                data-kt-scroll-offset='300px'
              >
                <div className='row align-items-center'>
                  <div className='col-md-3'>
                    <div className='fv-row mb-3'>
                      <label className=' required d-block fw-bold fs-6 mb-5 p-4'>Picture</label>
                    </div>
                  </div>
                  <div className='col-md-9'>
                    <div className='fv-row mb-3 pt-5'>
                      <div
                        className='image-input image-input-empty'
                        data-kt-image-input='true'
                        style={{backgroundImage: `url('${blankImg}')`}}
                      >
                        <div className='image-input-wrapper w-125px h-125px'></div>
                        <label
                          className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='change'
                          data-bs-toggle='tooltip'
                          title='Change avatar'
                        >
                          <i className='ki-duotone ki-pencil fs-6'>
                            <span className='path1'></span>
                            <span className='path2'></span>
                          </i>
                          <input
                            type='file'
                            name='avatar'
                            accept='.png, .jpg, .jpeg'
                            onChange={handleImage}
                          />
                          <input type='hidden' name='avatar_remove' />
                        </label>
                        <span
                          className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='cancel'
                          data-bs-toggle='tooltip'
                          title='Cancel avatar'
                        >
                          <i className='ki-outline ki-cross fs-3'></i>
                        </span>
                        <span
                          className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                          data-kt-image-input-action='remove'
                          data-bs-toggle='tooltip'
                          title='Remove avatar'
                        >
                          <i className='ki-outline ki-cross fs-3'></i>
                        </span>
                      </div>
                      <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                    </div>
                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-md-3'>
                    <div className='fv-row mb-3'>
                      <label className='required fw-bold fs-6 mb-2 p-4'>Full Name</label>
                    </div>
                  </div>
                  <div className='col-md-9'>
                    <div className='fv-row mb-3'>
                      <input
                        placeholder='Full name'
                        type='text'
                        className='form-control '
                        name='fullName'
                        value={fullName || ''}
                        onChange={handleChange}
                        autoComplete='off'
                      />
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-md-3'>
                    <div className='fv-row mb-3'>
                      <label className='required fw-bold fs-6 mb-2 p-4'>Country of Residence</label>
                    </div>
                  </div>
                  <div className='col-md-9'>
                    <div className='fv-row mb-3'>
                      <select className='form-control ' onChange={handleCountryChange}>
                        <option value='' disabled selected>
                          Select a country
                        </option>
                        {/* <option value={country}>{country}</option> */}
                        {countries.getAllCountries().map((countryData) => (
                          <option key={countryData.isoCode} value={countryData.isoCode}>
                            {countryData.name}
                          </option>
                        ))}
                      </select>
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-md-3'>
                    <div className='fv-row mb-3'>
                      <label className='required fw-bold fs-6 mb-2 p-4'>Current Address</label>
                    </div>
                  </div>
                  <div className='col-md-9'>
                    <div className='fv-row mb-3'>
                      <input
                        placeholder='Enter your current address'
                        type='text'
                        className='form-control '
                        name='currentAddress'
                        autoComplete='off'
                        // value={currentAddress || ''}
                        onChange={handleChange}
                      />
                      <div className='fv-plugins-message-container'>
                        <span role='alert'></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-md-3'>
                    <div className='fv-row mb-3'>
                      <label className='required fw-bold fs-6 mb-2 p-4'>Email</label>
                    </div>
                  </div>
                  <div className='col-md-9'>
                    <div className='fv-row mb-3'>
                      <input
                        placeholder='Enter your email'
                        type='email'
                        className='form-control '
                        name='email'
                        // autoComplete='off'
                        value={email || ''}
                        onChange={handleChange}
                      />
                      <div className='fv-plugins-message-container'>
                        <span role='alert'></span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row align-items-center'>
                  <div className='col-md-3'>
                    <div className='fv-row mb-3'>
                      <label className='required fw-bold fs-6 mb-2 p-4'>Phone Number</label>
                    </div>
                  </div>
                  <div className='col-md-3'>
                    <div className='fv-row mb-3'>
                      <div className='input-group'>
                        <select
                          id='countryCode'
                          className='form-control '
                          onChange={handleCountryCodeChange}
                          value={form.countryCode}
                        >
                          {countryCodes.map((code) => (
                            <option key={code} value={code}>
                              {`+${code}`}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='fv-row mb-3'>
                      <div className='input-group'>
                        <input
                          type='tel'
                          id='phoneNumber'
                          className='form-control '
                          onChange={handlePhoneNumberChange}
                          placeholder='Enter phone number'
                          value={phoneNumber || ''}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-end pt-4'>
                    {/* <button>
                  <img src={toAbsoluteUrl(`/media/buttons/SaveButton.svg`)} alt='' />
                  </button> */}

                    {isLoading ? (
                      <button
                        type='submit'
                        className='btn'
                        style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                        disabled={progress !== null && progress < 100}
                      >
                        <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                        Save
                      </button>
                    ) : (
                      <button
                        type='submit'
                        className='btn'
                        style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                        disabled={progress !== null && progress < 100}
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <>
              <form
                id='kt_modal_add_user_form'
                className='form'
                // onSubmit={formik.handleSubmit}
                noValidate
                onSubmit={handleSubmit}
              >
                <div
                  className='d-flex flex-column scroll-y me-n7 pe-7'
                  id='kt_modal_add_user_scroll'
                  data-kt-scroll='true'
                  data-kt-scroll-activate='{default: false, lg: true}'
                  data-kt-scroll-max-height='auto'
                  data-kt-scroll-dependencies='#kt_modal_add_user_header'
                  data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                  data-kt-scroll-offset='300px'
                >
                  <div className='row align-items-center'>
                    <div className='col-md-3'>
                      <div className='fv-row mb-3'>
                        <label className=' required d-block fw-bold fs-6 mb-5 p-4'>Picture</label>
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='fv-row mb-3 pt-5'>
                        <div
                          className='image-input image-input-empty'
                          data-kt-image-input='true'
                          style={{backgroundImage: `url('${avatarUrl || blankImg}')`}}
                        >
                          <div className='image-input-wrapper w-125px h-125px'></div>
                          <label
                            className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='change'
                            data-bs-toggle='tooltip'
                            title='Change avatar'
                          >
                            <i className='ki-duotone ki-pencil fs-6'>
                              <span className='path1'></span>
                              <span className='path2'></span>
                            </i>
                            <input
                              type='file'
                              name='avatar'
                              accept='.png, .jpg, .jpeg'
                              onChange={handleImage}
                            />
                            <input type='hidden' name='avatar_remove' />
                          </label>
                          <span
                            className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='cancel'
                            data-bs-toggle='tooltip'
                            title='Cancel avatar'
                          >
                            <i className='ki-outline ki-cross fs-3'></i>
                          </span>
                          <span
                            className='btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow'
                            data-kt-image-input-action='remove'
                            data-bs-toggle='tooltip'
                            title='Remove avatar'
                          >
                            <i className='ki-outline ki-cross fs-3'></i>
                          </span>
                        </div>
                        <div className='form-text'>Allowed file types: png, jpg, jpeg.</div>
                      </div>
                    </div>
                  </div>

                  <div className='row align-items-center'>
                    <div className='col-md-3'>
                      <div className='fv-row mb-3'>
                        <label className='required fw-bold fs-6 mb-2 p-4'>Full Name</label>
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='fv-row mb-3'>
                        <input
                          placeholder='Full name'
                          type='text'
                          className='form-control '
                          name='fullName'
                          value={fullName || ''}
                          onChange={handleChange}
                          autoComplete='off'
                        />
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row align-items-center'>
                    <div className='col-md-3'>
                      <div className='fv-row mb-3'>
                        <label className='required fw-bold fs-6 mb-2 p-4'>
                          Country of Residence
                        </label>
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='fv-row mb-3'>
                        {/* <select
                          className='form-control '
                          onChange={handleCountryChange}
                        >
                          <option value={country} selected>
                            {country}
                          </option>
                          {countries.getAllCountries().map((countryData) => (
                            <option key={countryData.isoCode} value={countryData.isoCode}>
                              {countryData.name}
                            </option>
                          ))}
                        </select> */}
                        <select
                          className='form-control '
                          value={country} // Use value instead of selected to set the selected option
                          onChange={handleCountryChange}
                        >
                          {countries.getAllCountries().map((countryData) => (
                            <option key={countryData.isoCode} value={countryData.isoCode}>
                              {countryData.name}
                            </option>
                          ))}
                        </select>

                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row align-items-center'>
                    <div className='col-md-3'>
                      <div className='fv-row mb-3'>
                        <label className='required fw-bold fs-6 mb-2 p-4'>Current Address</label>
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='fv-row mb-3'>
                        <input
                          placeholder='Enter your current address'
                          type='text'
                          className='form-control '
                          name='currentAddress'
                          autoComplete='off'
                          value={currentAddress}
                          onChange={handleChange}
                        />
                        <div className='fv-plugins-message-container'>
                          <span role='alert'></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row align-items-center'>
                    <div className='col-md-3'>
                      <div className='fv-row mb-3'>
                        <label className='required fw-bold fs-6 mb-2 p-4'>Email</label>
                      </div>
                    </div>
                    <div className='col-md-9'>
                      <div className='fv-row mb-3'>
                        <input
                          placeholder='Enter your email'
                          type='email'
                          className='form-control '
                          name='email'
                          // autoComplete='off'
                          value={email || ''}
                          onChange={handleChange}
                        />
                        <div className='fv-plugins-message-container'>
                          <span role='alert'></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row align-items-center'>
                    <div className='col-md-3'>
                      <div className='fv-row mb-3'>
                        <label className='required fw-bold fs-6 mb-2 p-4'>Phone Number</label>
                      </div>
                    </div>
                    <div className='col-md-3'>
                      <div className='fv-row mb-3'>
                        <div className='input-group'>
                          {/* <select
                            id='countryCode'
                            className='form-control '
                            onChange={handleCountryCodeChange}
                            value={countryCode}
                          >
                            
                            {countryCodes.map((code) => (
                              <option key={code} value={code}>
                                {`+${code}`}
                              </option>
                            ))}
                          </select> */}
                          <input
                            placeholder='CountryCode'
                            type='text'
                            className='form-control '
                            name='countryCode'
                            // autoComplete='off'
                            value={countryCode || ''}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='fv-row mb-3'>
                        <div className='input-group'>
                          <input
                            type='tel'
                            id='phoneNumber'
                            className='form-control '
                            onChange={handlePhoneNumberChange}
                            placeholder='Enter phone number'
                            value={phoneNumber}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-end pt-4'>
                      {/* <button>
                  <img src={toAbsoluteUrl(`/media/buttons/SaveButton.svg`)} alt='' />
                  </button> */}

                      {isLoading ? (
                        <button
                          type='submit'
                          className='btn'
                          style={{backgroundColor: '#44DBAF', color: '#FFFFFF'}}
                          disabled={progress !== null && progress < 100}
                        >
                          <span className='spinner-border spinner-border-sm align-middle m-2'></span>
                          Save
                        </button>
                      ) : (
                        <button
                          type='submit'
                          className='btn'
                          style={{backgroundColor: '#1CBB8D', color: '#fff'}}
                          disabled={progress !== null && progress < 100}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default BackgroundCheck
