import React, { useEffect, useState } from "react";
import { PageTitle } from "../../../../_metronic/layout/core";
import { KTCard } from "../../../../_metronic/helpers";
import { useAuth } from "../../../modules/auth";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import db from "../../../../services/firebase";
import { TestListLoading } from "../../tests/components/loading/TestListLoading";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import ExcelJS from "exceljs";
import { Helmet } from "react-helmet";
import { toast, ToastContainer } from "react-toastify";
import UpgradePlan from "app/pages/controls/components/submodal/UpgradePlan";
type Location = string | null;

type DateState = {
  startDate: Date | null;
  endDate: Date | null;
};
const ActivityLog = () => {
  const [activities, setActivities] = useState<any[]>([]);
  const { currentUser } = useAuth();
  const [dateState, setDateState] = useState<DateState>({
    startDate: null,
    endDate: null,
  });
  const [selectedLocation, setSelectedLocation] = useState<Location>(null);
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const [filteredActivities, setFilteredActivities] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [startDate, endDate] = dateRange;
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedNumber, setSelectedNumber] = useState("100");
  let ITEMS_PER_PAGE = parseInt(selectedNumber);
  const pageCount = Math.ceil(filteredActivities.length / ITEMS_PER_PAGE);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    getActivities();
  }, []);

  useEffect(() => {
    const filtered = activities.filter((item: any) => {
      const matchesLocation =
        selectedLocation === null || item.target === selectedLocation;
      const matchesAction =
        selectedAction === null || item.action === selectedAction;
      const isWithinDateRange =
        !startDate ||
        !endDate ||
        (item.timestamp.toDate() >= startDate &&
          item.timestamp.toDate() <= endDate);
      return matchesLocation && matchesAction && isWithinDateRange;
    });
    setFilteredActivities(filtered);
  }, [activities, selectedLocation, selectedAction, startDate, endDate]);

  const getActivities = async () => {
    try {
      const tenantId = currentUser?.tenantId || "";
      const tenantRef = doc(db, "tenants", tenantId);
      const activitiesCollectionRef = collection(tenantRef, "activities");

      let activitiesQuery = query(activitiesCollectionRef);

      if (dateState.startDate && dateState.endDate) {
        const startTimestamp = Timestamp.fromDate(dateState.startDate);
        const endTimestamp = Timestamp.fromDate(dateState.endDate);
        activitiesQuery = query(
          activitiesCollectionRef,
          where("timestamp", ">=", startTimestamp),
          where("timestamp", "<=", endTimestamp)
        );
      }

      const unsubscribe = onSnapshot(activitiesQuery, (snapshot) => {
        const activitiesData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setActivities(activitiesData);
      });

      return unsubscribe;
    } catch (error) {
      console.error("Error getting activities:", error);
    }
  };

  const handlePageClick = ({ selected }: { selected: number }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * ITEMS_PER_PAGE;

  const paginatedData = filteredActivities
    .slice()
    .sort((a, b) => {
      // Convert Timestamps to Date objects for comparison
      const dateA = a.timestamp.toDate();
      const dateB = b.timestamp.toDate();
      return dateB - dateA; // Sort in descending order: most recent dates first
    })
    .slice(offset, offset + ITEMS_PER_PAGE);

  const formatDate = (dateString: string): string => {
    try {
      let [month, day, year] = dateString.split("/");

      // Ensure year is 4 digits
      if (year.length === 2) {
        year = `20${year}`;
      }

      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

      const formattedDay = parseInt(day);
      const formattedMonth = date.toLocaleString("default", { month: "long" });
      const formattedYear = year;

      return `${formattedDay} ${formattedMonth}, ${formattedYear}`;
    } catch (error) {
      return "";
    }
  };

  function formatTimestamp(timestamp: Timestamp): string {
    const date = timestamp.toDate();

    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Round minutes to nearest 12
    minutes = Math.round(minutes / 12) * 12;

    // Adjust hours if minutes round up to 60
    if (minutes === 60) {
      minutes = 0;
      hours += 1;
    }

    // Convert to 12-hour format
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    // Format the result
    const formattedTime = `${hours}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;

    return formattedTime;
  }

  const exportData = async () => {
    const db = getFirestore();
    const tenantId = currentUser?.tenantId;
    const tenantRef = doc(db, "tenants", tenantId!);
    const activitiesRef = collection(tenantRef, "activities");
    const querySnapshot = await getDocs(
      query(activitiesRef, orderBy("timestamp", "desc"))
    );

    const data = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      if (data.timestamp) {
        data.timestamp = data.timestamp.toDate();
      }
      return data;
    });

    if (data.length > 0) {
      const fields = [
        { key: "date", header: "Date", width: 20 },
        { key: "email", header: "Email", width: 30 },
        { key: "username", header: "Username", width: 30 },
        { key: "action", header: "Action", width: 30 },
        { key: "target", header: "Target", width: 20 },
        { key: "message", header: "Message", width: 50 },
        { key: "timestamp", header: "Timestamp", width: 30 },
      ];

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Activities");

      // Set Columns
      worksheet.columns = fields.map((field) => ({
        header: field.header,
        key: field.key,
        width: field.width,
      }));

      // Styling Header Row
      const headerRow = worksheet.getRow(1);
      headerRow.font = { bold: true, color: { argb: "FFFFFFFF" }, size: 10 };
      headerRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF000000" }, // Black background like the employees export
      };
      headerRow.height = 30;
      headerRow.alignment = { horizontal: "center", vertical: "middle" };
      worksheet.views = [{ state: "frozen", ySplit: 1 }]; // Freeze header row

      // Add Rows
      data.forEach((row) => {
        const rowData = fields.reduce((acc, field) => {
          let value = row[field.key] || row[field.key.toLowerCase()] || "";

          // Format timestamp for readability
          if (field.key === "timestamp") {
            value =
              row[field.key] instanceof Timestamp
                ? row[field.key].toDate().toLocaleString()
                : value;
          }

          acc[field.key] = value;
          return acc;
        }, {});

        const addedRow = worksheet.addRow(rowData);
        addedRow.height = 20;
        addedRow.font = { size: 10 };
      });

      // Export the file
      const buffer = await workbook.xlsx.writeBuffer();
      const excelBlob = new Blob([buffer], {
        type: "application/octet-stream",
      });
      saveAs(excelBlob, "Activity_logs.xlsx");

      // Success Toast
      toast.success("Activity log data exported successfully", {
        position: toast.POSITION.TOP_CENTER,
        className: "bg-white",
        style: {
          borderRadius: "8px",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          border: "1px solid #e0e0e0",
          color: "black",
        },
        progressStyle: {
          background: "#1CBB8D",
        },
        icon: ({ theme, type }) => (
          <svg
            width="26"
            height="26"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ color: "#1CBB8D" }}
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z"
              fill="currentColor"
            />
          </svg>
        ),
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>SecureSlate | Activity log</title>
      </Helmet>
      <PageTitle
        icon="Activity.svg"
        title="Activity Logs"
        desc={"Monitor all user activities within SecureSlate."}
        // breadcrumbs={usersBreadcrumbs}
      >
        Data Room
      </PageTitle>
      <KTCard>
        <div className="card-header border-0 pt-6 h-100">
          <div className="card-title">
            <div className="d-flex align-items-center position-relative my-1 me-4">
              <div className="me-4">
                <DatePicker
                  selectsRange={true}
                  startDate={startDate || undefined}
                  endDate={endDate || undefined}
                  onChange={(update: [Date | null, Date | null]) => {
                    setDateRange(update); // Update dateRange state
                    if (update[0] && update[1]) {
                      setDateState({
                        startDate: update[0],
                        endDate: update[1],
                      }); // Update dateState for filtering
                    } else {
                      setDateState({
                        startDate: null,
                        endDate: null,
                      });
                    }
                  }}
                  customInput={
                    <input
                      style={{ borderColor: "#C5C5C5", height: "40px" }}
                      className="form-control"
                    />
                  }
                  isClearable={true}
                  placeholderText="Pick date range"
                  className="form-control"
                  wrapperClassName="custom-datepicker"
                  clearButtonStyle={{
                    backgroundColor: "#1CBB8D",
                    color: "#fff",
                  }}
                />
                <style>
                  {`

    .react-datepicker__close-icon::before {
      color: #1CBB8D;
      font-size: 1.5rem; 
    }
    .react-datepicker__close-icon:hover::before {
      color: #218838; 
    }
    /* Change the selected day and hover colors */
    .react-datepicker__day--selected {
      background-color: #1CBB8D; 
      color: #fff; 
    }
    .react-datepicker__day:hover {
      background-color: #148363;
      color: #fff;
    }
  
   
  `}
                </style>
              </div>
            </div>
          </div>
          <div className="card-toolbar">
            <div className="d-flex align-items-center justify-content-end ">
              <span className="fs-6 fw-semibold mx-4">Location:</span>
              <select
                id="status"
                className="form-select me-4"
                aria-label="Select Status"
                style={{ width: "70%", height: "40px" }}
                value={selectedLocation || ""}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setSelectedLocation(e.target.value || null)
                }
              >
                <option value="" selected>
                  Show All
                </option>
                <option value="Asset Management">Asset Management</option>
                <option value="Authentication">Authentication</option>
                <option value="Controls">Controls</option>
                <option value="Data Room">Data Room</option>
                <option value="Employees">Employees</option>
                <option value="Integrations">Integrations</option>
                <option value="Policies">Policies</option>
                <option value="Risk Management">Risk Management</option>
                <option value="Settings">Settings</option>
                <option value="Tests">Tests</option>
                <option value="Vendor Risk">Vendor Risk</option>
              </select>
            </div>
            <button
              type="button"
              // onClick={exportData}
              onClick={() =>
                currentUser?.account_type === "Trial"
                  ? setShowModal(true)
                  : exportData()
              }
              className="btn btn-sm fs-6"
              style={{ height: "40px", background: "#1CBB8D", color: "#fff" }}
              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = "#148363")
              }
              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                (e.currentTarget.style.backgroundColor = "#1CBB8D")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="icon icon-tabler icons-tabler-outline icon-tabler-download me-2 mb-1"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                <path d="M7 11l5 5l5 -5" />
                <path d="M12 4l0 12" />
              </svg>
              Export
            </button>
          </div>
        </div>
        <div className="card-body py-6">
          <div className="table-responsive">
            {/* begin::Table */}
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
              {/* begin::Table head */}
              <thead>
                <tr className="fw-bold fs-7" style={{ color: "#5A5A5A" }}>
                  <th className="min-w-150px">DATE</th>

                  <th className="min-w-150px">ACTION</th>
                  <th className="min-w-150px">LOCATION</th>
                  <th className="min-w-300px">DETAILS</th>
                  <th className="min-w-200px">USER</th>
                </tr>
              </thead>

              {paginatedData && paginatedData.length > 0 ? (
                <tbody>
                  {paginatedData.map((item) => (
                    <tr key={item.id}>
                      <td>
                        {" "}
                        <div className="d-flex flex-column">
                          <span className="fw-semibold text-dark fs-6">
                            {formatDate(item.date)}
                          </span>
                          <span className="fs-7" style={{ color: "#5A5A5A" }}>
                            {formatTimestamp(item.timestamp)}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center fw-semibold">
                          {item.action}
                        </div>
                      </td>

                      <td>
                        <div
                          className="badge fs-7 text-dark text-bold m-1"
                          style={{ background: "#F6F6F6" }}
                        >
                          {item.target}
                        </div>
                      </td>

                      <td>
                        <div className="fs-6 fw-semibold">{item.message}</div>
                      </td>

                      <td>
                        <div className="d-flex align-items-center py-0">
                          {item.username ? (
                            <>
                              <div className="symbol symbol-circle symbol-35px me-3">
                                <span
                                  className="symbol-label fs-7 fw-bold"
                                  style={{ backgroundColor: "#DAF9EF" }}
                                >
                                  {item.username
                                    ? (() => {
                                        const nameParts =
                                          item.username.split(" ");
                                        if (nameParts.length > 1) {
                                          return `${nameParts[0]
                                            .charAt(0)
                                            .toUpperCase()}${nameParts[1]
                                            .charAt(0)
                                            .toUpperCase()}`;
                                        } else {
                                          return `${nameParts[0]
                                            .charAt(0)
                                            .toUpperCase()}${nameParts[0]
                                            .charAt(1)
                                            .toUpperCase()}`;
                                        }
                                      })()
                                    : ""}
                                </span>
                              </div>
                            </>
                          ) : (
                            <>-</>
                          )}

                          <div className="d-flex flex-column">
                            <a
                              href="#"
                              className="fw-semibold text-dark text-hover-primary fs-6"
                            >
                              {item.username}
                            </a>
                            <span className="fs-7" style={{ color: "#5A5A5A" }}>
                              {item.email}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <>
                  <tbody>
                    <tr>
                      <td colSpan={10} className="text-center">
                        {!showEmptyState ? (
                          // <td colSpan={10}>
                          <div
                            className="d-flex justify-content-center align-items-center text-center"
                            style={{ height: "500px" }}
                          >
                            <TestListLoading />
                          </div>
                        ) : (
                          <div className="m-20">
                            <img src="/media/buttons/emptystate.svg" alt="" />
                            <div className=" fs-5">
                              <p style={{ fontWeight: "bold" }}>
                                No filter results
                              </p>
                            </div>
                            <p>Maybe try a different filter or reset filters</p>
                          </div>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </>
              )}

              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
        </div>
      </KTCard>
      {filteredActivities.length >= 50 && (
        <div className="d-flex justify-content-between">
          <div
            id=""
            className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar"
          >
            <div>
              <select
                name="kt_permissions_table_length"
                aria-controls="kt_permissions_table"
                className="form-select"
                id="dt-length-1"
                value={selectedNumber}
                onChange={(e) => setSelectedNumber(e.target.value)}
              >
                {/* <option value='20'>20</option> */}
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="150">150</option>
              </select>
              {/* <label for='dt-length-1'></label> */}
            </div>
          </div>

          <div className="d-flex justify-content-end my-10">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      )}
      <ToastContainer />
      <UpgradePlan show={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};

export default ActivityLog;
