/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {OnDashPageTitle, PageLink} from '../cores/OnDashPageData'
import {PageTitle} from '../cores/card1'
import {useAuth} from '../../modules/auth'
import {Helmet} from 'react-helmet'
import { doc, onSnapshot} from 'firebase/firestore'
import db from '../../../services/firebase'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'training',
    path: '/training',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
type Props = {
  completed: boolean
}

const TrainingPage: FC<Props> = ({completed}) => {
  // const [CCPACompleted, setCCPACompleted] = useState(false)
  // const [HIPPACompleted, setHIPPACompleted] = useState(false)
  // const [PCICompleted, setPCICompleted] = useState(false)
  const [PCISecureCompleted, setPCISecureCompleted] = useState(false)
  const [SATCompleted, setSATCompleted] = useState(false)
  // const [GDPRCompleted, setGDPRCompleted] = useState(false)

  const {currentUser} = useAuth()

  useEffect(() => {
    let unsubscribe = () => {}

    const fetchTrainingData = async () => {
      try {
        const tenantId = currentUser?.tenantId || ''
        const userId = currentUser?.uid

        if (!tenantId || !userId) {
          return
        }

        // Real-time listener for SATPCISecure training data
        const SATDocRef = doc(db, 'tenants', tenantId, 'onboarding', userId, 'training', 'SAT')
        const unsubscribeSAT = onSnapshot(SATDocRef, (doc) => {
          const SATPCISecureData = doc.data() || {}

          const isSATChecked = SATPCISecureData.is_checked || false

          setSATCompleted(isSATChecked)
        })

        const PCISecureDocRef = doc(
          db,
          'tenants',
          tenantId,
          'onboarding',
          userId,
          'training',
          'PCISecure'
        )
        const unsubscribePCISecure = onSnapshot(PCISecureDocRef, (doc) => {
          const PCISecureData = doc.data() || {}

          const isPCISecureChecked = PCISecureData.is_checked || false
          setPCISecureCompleted(isPCISecureChecked)
        })

        // Combine the unsubscribe functions
        unsubscribe = () => {
          unsubscribeSAT()
          unsubscribePCISecure()
        }
      } catch (error) {
        console.error('Error fetching training data:', error)
      }
    }

    fetchTrainingData()

    // Cleanup on component unmount
    return () => {
      unsubscribe()
    }
  }, [currentUser])

  return (
    <>
      <Helmet>
        <title>SecureSlate | Training</title>
      </Helmet>
      <OnDashPageTitle
        icon='training.svg'
        title='Training'
        desc="Boost your awareness in safeguarding your company's digital and physical Security"
      />
      <div className='d-flex flex-column'>
        <PageTitle
          icon='security.svg'
          title='Security Awareness Training'
          desc='Safeguard your digital workspace.'
          breadcrumbs={usersBreadcrumbs}
          task={SATCompleted ? 'Completed' : ''}
          buttonUrl='/training/SAT'
        />
        {/* <PageTitle
          icon='hippa.svg'
          title='HIPAA Training'
          desc='Protect data and privacy with HIPAA.'
          breadcrumbs={usersBreadcrumbs}
          task={HIPPACompleted ? 'Completed' : ''}
          buttonUrl='/training/HIPAA'
        />
        <PageTitle
          icon='PCIT.svg'
          title='PCI Training'
          desc='Safeguard sensitive payment data.'
          breadcrumbs={usersBreadcrumbs}
          task={PCICompleted ? 'Completed' : ''}
          buttonUrl='/training/PCI'
        /> */}
        {/* <PageTitle
          icon='PCI.svg'
          title='PCI Secure Code Training'
          desc='Secure coding practices.'
          breadcrumbs={usersBreadcrumbs}
          task={PCISecureCompleted ? 'Completed' : ''}
          buttonUrl='/training/PCISecure'
        /> */}
        {/* <PageTitle
          icon='CCPA.svg'
          title='CCPA Training'
          desc='Safeguard customer data and ensure compliance.'
          breadcrumbs={usersBreadcrumbs}
          task={CCPACompleted ? 'Completed' : ''}
          buttonUrl='/training/CCPA'
        />
        <PageTitle
          icon='GDPRT.svg'
          title='GDPR Training'
          desc='Protect data and privacy with HIPAA.'
          breadcrumbs={usersBreadcrumbs}
          task={GDPRCompleted ? 'Completed' : ''}
          buttonUrl='/training/GDPR'
        /> */}
      </div>
    </>
  )
}



export {TrainingPage}
