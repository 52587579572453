import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import {Badge, Card} from 'react-bootstrap'
import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import {useAuth} from 'app/modules/auth'
import db from 'services/firebase'
import {toast, ToastContainer} from 'react-toastify'
import {KTIcon, toAbsoluteUrl} from '_metronic/helpers'
import {AuditPageTitle} from '_metronic/layout/core/AuditPageData'
import Swal from 'sweetalert2'
import {AuditListLoading} from '../components/loading/AuditListLoading'
import JSZip from 'jszip'
import RequestAccess from '../components/modal/RequestAcess'

const AuditComplete = () => {
  const {currentUser} = useAuth()
  const [audits, setAudits] = useState<any[]>([])
  const [filteredAudits, setFilteredAudits] = useState<any[]>([])
  const [dataroom, setDataroom] = useState<any[]>([])
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const [selectedFramework, setSelectedFramework] = useState('')
  const [owners, setOwners] = useState<any[]>([])
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [selectedAuditId, setSelectedAuditID] = useState('')
  const [showCompleteModal, setShowCompleteModal] = useState(false)
  const [currentAuditId, setCurrentAuditId] = useState('')
  const [auditprop, setAuditProp] = useState<any[]>([])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 2000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    setFilteredAudits(audits)
  }, [audits])

  useEffect(() => {
    const unsubscribe = getActiveFramworks()
    return () => unsubscribe && unsubscribe()
  }, [currentUser])

  const getActiveFramworks = () => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const testsCollectionRef = collection(tenantRef, 'active_frameworks')

    const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
      const frameworksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
        frameworks: doc.data().frameworks,
      }))
      const frameworks = frameworksData[0]?.frameworks || []
      setActiveFrameworks(frameworks)
    })

    return unsubscribe
  }

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'audits')

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      let Data = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          status: doc.data()?.status,
          createdAt: doc.data().createdAt,
          auditor: doc.data().auditor,
        }))
        .filter((data) => data.status === 'Completed')
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      if (currentUser?.role === 'Auditor') {
        Data = Data.filter((audit) => audit.auditor?.id === currentUser?.uid)
      }
      setAudits(Data)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'dataroom')

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const Data = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setDataroom(Data)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const data = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          role: doc.data().role,
          availability: doc.data().availability,
        }))
        .filter((item) => item.role === 'Auditor')
      setOwners(data)
    })

    return unsubscribe
  }, [])

  const handleRequestAccess = (auditId: string, audit) => {
    setCurrentAuditId(auditId)
    setAuditProp(audit)
    setShowCompleteModal(true)
  }

  const handleMarkAsIncomplete = async (framework, auditId, auditorId) => {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    const todaysDate = `${month}/${day}/${year}`

    const tenantId = currentUser?.tenantId || ''
    const tenantRef2 = doc(db, 'tenants', tenantId)
    const policyRef = doc(tenantRef2, 'audits', auditId)

    const selectedOwner = owners.find((owner) => owner.uid === auditorId)

    if (selectedOwner) {
      if (selectedOwner.availability) {
        await updateDoc(policyRef, {
          status: 'Incompleted',
          auditCompleteDate: '',
          request_access: deleteField(),
        })

        const tenantRef = doc(db, 'tenants', tenantId)
        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} has marked the audit for the ${framework} framework assigned to ${selectedOwner.full_name} as incomplete.`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          // email: email,
          uid: currentUser?.uid,
          username: currentUser?.userName,
          action: 'Audit updated',
          target: 'Audits',
          email: currentUser?.email,
          date: todaysDate,
        })

        toast.success('Audit marked as Incomplete', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
      } else {
        toast.error('Auditor is engaged in another audit', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            color: 'black',
          },
          autoClose: 3000,
        })
      }
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    if (searchTerm === '') {
      setFilteredAudits(audits)
      setSearchLength(false)
    } else {
      const filteredItems = audits.filter(
        (audit) =>
          Array.isArray(audit.framework) &&
          audit.framework.some(
            (fw) => typeof fw === 'string' && fw.toLowerCase().includes(searchTerm)
          )
      )
      setFilteredAudits(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleDownloadAuditReport = async (auditID, auditDocument) => {
    try {
      if (
        auditDocument &&
        'fileURL' in auditDocument &&
        'fileName' in auditDocument &&
        'fileType' in auditDocument
      ) {
        const response = await fetch(auditDocument.fileURL)
        const fileContent = await response.blob()

        const blob = new Blob([fileContent], {type: auditDocument.fileType})

        const blobUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = blobUrl
        link.download = auditDocument.fileName

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        URL.revokeObjectURL(blobUrl)

        toast.success('Audit report downloaded', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
      } else {
        toast.error('Audit report missing', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            color: 'black',
          },
          autoClose: 3000,
        })
      }
    } catch (error) {
      toast.error('Error downloading file', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          color: 'black',
        },
        autoClose: 3000,
      })
    }
  }

  const handleDownloadReport = async (auditID, dataroomFolderID, auditEvidenceFolderName) => {
    setDownloadLoading(true)
    setSelectedAuditID(auditID)
    try {
      if (dataroomFolderID) {
        const filterData = dataroom.filter((data) => data.parentFolder === dataroomFolderID)

        if (filterData.length > 0) {
          const zip = new JSZip()

          for (const fileData of filterData) {
            const {downloadUrl, name} = fileData

            const response = await fetch(downloadUrl)

            if (!response.ok) {
              console.error(`Failed to fetch file: ${name}`)
              continue
            }

            const blob = await response.blob()
            zip.file(name || 'file', blob)
          }

          const zipBlob = await zip.generateAsync({type: 'blob'})
          const blobUrl = URL.createObjectURL(zipBlob)

          const link = document.createElement('a')
          link.href = blobUrl
          link.download = `${auditEvidenceFolderName}.zip`
          link.click()

          URL.revokeObjectURL(blobUrl)
          toast.success('Audit evidence downloaded', {
            position: toast.POSITION.TOP_CENTER,
            className: 'bg-white',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              border: '1px solid #e0e0e0',
              color: 'black',
            },
            progressStyle: {
              background: '#1CBB8D',
            },
            icon: ({theme, type}) => (
              <svg
                width='26'
                height='26'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
                style={{color: '#1CBB8D'}}
              >
                <path
                  d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                  fill='currentColor'
                />
              </svg>
            ),
            autoClose: 3000,
          })
          setDownloadLoading(false)
        } else {
          setDownloadLoading(false)
        }
      }
    } catch (error) {
      setDownloadLoading(false)
    }
  }

  const handleDelete = async (audit) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${audit.framework[0]} Audit`,
        text: `Are you sure you want to remove ${audit.framework[0]} audit ? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'audits')
        const docRef = doc(policyRef, audit.id)
        await deleteDoc(docRef)

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${audit.framework[0]}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the audit evidence.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }

  const handleApplyFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const status = e.target.value

    if (status === 'All') {
      // Show all audits if "All" is selected
      setFilteredAudits(audits)
    } else {
      // Filter audits based on the selected framework
      const filtered = audits.filter((data) => data.framework && data.framework.includes(status))
      setFilteredAudits(filtered)
    }
  }

  return (
    <>
      <Helmet>
        <title>SecureSlate | Audits</title>
      </Helmet>
      <AuditPageTitle
        icon='Icon-2.svg'
        title='Audits'
        // desc={totalVendor.toString() + ' Vendors'}
        desc={'Track and manage your security audits in one centralized view'}
      />
      {/* <AddAudit isOpen={showAddaudit} onClose={handleCloseAudit} /> */}
      <div className='card-header border-0   mb-8'>
        <div className='d-flex justify-content-between align-items-center w-100'>
          {/* Search on the left side */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-3' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control ps-12'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
          <div className='d-flex align-items-center justify-content-end'>
            <span className='fs-6 fw-semibold me-2'>Framework:</span>
            <select
              id='status'
              className='form-select'
              aria-label='Select Status'
              style={{width: '180px', height: '40px'}}
              value={selectedFramework}
              onChange={(e) => {
                setSelectedFramework(e.target.value)
                handleApplyFilter(e)
              }}
            >
              <option value='All'>Select framework</option>
              {activeFrameworks.map((framework) => (
                <option key={framework} value={framework}>
                  {framework}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {filteredAudits && filteredAudits.length > 0 ? (
        filteredAudits.map((audit) => {
          return (
            <Card className='mb-6'>
              <Card.Header className='d-flex align-items-center'>
                <div className='d-flex align-items-center me-auto'>
                  <div className='bg-light rounded  me-3'>
                    <img
                      src='/media/logos/ISO.svg'
                      alt=''
                      className='rounded'
                      style={{width: '40px', height: '40px'}}
                    ></img>
                  </div>
                  <div>
                    <h5 className='mb-0 d-flex align-items-center'>
                      {Array.isArray(audit.framework) && audit.framework[0]
                        ? audit.framework[0]
                        : ''}{' '}
                      Audit
                      <Badge
                        bg='secondary'
                        className='ms-2'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Folder name where the audit evidence is stored.'
                      >
                        {audit.auditEvidenceFolderName ? audit.auditEvidenceFolderName : ''}
                      </Badge>
                      {audit.auditCompleteDate ? (
                        <Badge bg='primary' className='ms-2 text-white'>
                          Completed
                        </Badge>
                      ) : (
                        ''
                      )}
                      {currentUser?.role !== 'Auditor' && audit.auditor && (
                        <Badge
                          bg='secondary'
                          className='ms-2'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title={audit.auditor.email || ''}
                        >
                          {audit.auditor.fullName}
                        </Badge>
                      )}
                      {audit.request_access ? (
                        <Badge
                          bg='warning'
                          className='ms-2 text-white'
                          data-bs-toggle='tooltip'
                          data-bs-placement='top'
                          title={audit.request_access?.accessReason || ''}
                        >
                          {audit.request_access?.status === 'Requested' ? 'Request Access' : ''}
                        </Badge>
                      ) : (
                        ''
                      )}
                    </h5>
                  </div>
                </div>
                {downloadLoading && audit.id === selectedAuditId && (
                  <div
                    className='spinner-border'
                    role='status'
                    style={{
                      color: '#44DBAF',
                      width: '1.5rem',
                      height: '1.5rem',
                    }}
                  >
                    <span className='visually-hidden'>Loading...</span>
                  </div>
                )}
                <div className='dropdown mx-2'>
                  <button
                    className='btn btn-secondary border-0 p-0 m-2'
                    type='button'
                    id='dropdownMenu2'
                    data-bs-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    <img src='/media/buttons/Options.svg' alt='Options' onClick={toggleDropdown} />
                  </button>
                  <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                    {currentUser?.role === 'Owner' && (
                      <li>
                        <button
                          className='dropdown-item text-muted text-hover-primary'
                          type='button'
                          onClick={() =>
                            handleMarkAsIncomplete(audit.framework[0], audit.id, audit.auditor.id)
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-xbox-x mx-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z' />
                            <path d='M9 8l6 8' />
                            <path d='M15 8l-6 8' />
                          </svg>{' '}
                          Mark as Incomplete
                        </button>
                      </li>
                    )}
                    {currentUser?.role === 'Auditor' && (
                      <li>
                        <button
                          className='dropdown-item text-muted text-hover-primary'
                          type='button'
                          onClick={() => handleRequestAccess(audit.id, audit)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-shield-plus mb-1 mx-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M12.462 20.87c-.153 .047 -.307 .09 -.462 .13a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 .11 6.37' />
                            <path d='M16 19h6' />
                            <path d='M19 16v6' />
                          </svg>{' '}
                          Request Access
                        </button>
                      </li>
                    )}
                    {currentUser?.role !== 'Auditor' && (
                      <li>
                        <button
                          className='dropdown-item text-muted text-hover-primary'
                          type='button'
                          onClick={() =>
                            handleDownloadReport(
                              audit.id,
                              audit.dataroomFolderID,
                              audit.auditEvidenceFolderName
                            )
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                            <path d='M7 11l5 5l5 -5' />
                            <path d='M12 4l0 12' />
                          </svg>{' '}
                          Export Documents
                        </button>
                      </li>
                    )}
                    {audit.audit_documents && audit.audit_documents.length > 0 && (
                      <li>
                        <button
                          className='dropdown-item text-muted text-hover-primary'
                          type='button'
                          onClick={() =>
                            handleDownloadAuditReport(audit.id, audit.audit_documents[0])
                          }
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                            <path d='M7 11l5 5l5 -5' />
                            <path d='M12 4l0 12' />
                          </svg>{' '}
                          Export Audit Report
                        </button>
                      </li>
                    )}
                    {currentUser?.role !== 'Auditor' && (
                      <li>
                        <button
                          className='dropdown-item'
                          type='button'
                          style={{color: '#F15363'}}
                          onClick={(e) => handleDelete(audit)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='18'
                            height='18'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='#F15363'
                            strokeWidth='2'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M4 7l16 0' />
                            <path d='M10 11l0 6' />
                            <path d='M14 11l0 6' />
                            <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                            <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                          </svg>{' '}
                          Remove
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              </Card.Header>
              <Card.Body>
                <div className='row'>
                  <div className='col-md-4'>
                    <h6 className='mb-3'>Audit Timeline</h6>
                    <ul className='list-unstyled'>
                      {audit.auditStartDate && (
                        <li className=' position-relative'>
                          <div className='position-absolute' style={{left: '7px', top: '8px'}}>
                            <i className='bi bi-check-circle-fill text-primary'></i>
                          </div>
                          <div className='ms-4 pb-3 border-start ps-3'>
                            <strong className='ms-2'>Auditor gains access</strong>
                            <div className='text-muted small ms-2'>{audit.auditStartDate}</div>
                          </div>
                        </li>
                      )}
                      {audit.auditStartDate && (
                        <li className=' position-relative'>
                          <div className='position-absolute' style={{left: '7px', top: '8px'}}>
                            <i className='bi bi-check-circle-fill text-primary'></i>
                          </div>
                          <div className='ms-4 pb-3 border-start ps-3'>
                            <strong className='ms-2'>Audit begins</strong>
                            <div className='text-muted small ms-2'>{audit.auditStartDate}</div>
                          </div>
                        </li>
                      )}
                      {audit.end_date && (
                        <li className='mb-3 position-relative'>
                          {audit.auditCompleteDate ? (
                            <div className='position-absolute' style={{left: '7px', top: '8px'}}>
                              <i className='bi bi-check-circle-fill text-primary '></i>
                            </div>
                          ) : (
                            <div className='position-absolute' style={{left: '7px', top: '8px'}}>
                              <i className='bi bi-check-circle-fill '></i>
                            </div>
                          )}
                          <div className='ms-4 pb-3 border-start ps-5'>
                            <strong>Audit concludes</strong>
                            <div className='text-muted small'>{audit.auditCompleteDate}</div>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className='col-md-8'>
                    <h6 className='mb-3'>Audit Documents</h6>
                    {/* <ProgressBar className='mb-3'>
                      <ProgressBar variant='danger' now={audit.TotalControls} key={1} />
                      <ProgressBar variant='primary' now={audit.TotalTests} key={2} />
                      <ProgressBar variant='secondary' now={audit.TotalEmployees} key={3} />
                      <ProgressBar variant='warning' now={audit.TotalRisks} key={4} />
                      <ProgressBar variant='success' now={audit.TotalAssets} key={5} />
                      <ProgressBar variant='info' now={audit.TotalVendors} key={6} />
                    </ProgressBar> */}

                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='d-flex justify-content-between mb-2'>
                          <span>
                            <span
                              className='bg-primary rounded-circle d-inline-block me-2'
                              style={{width: '10px', height: '10px'}}
                            ></span>{' '}
                            Policy evidences
                          </span>
                          <span>0</span>
                        </div>
                        <div className='d-flex justify-content-between mb-2'>
                          <span>
                            <span
                              className='bg-primary rounded-circle d-inline-block me-2'
                              style={{width: '10px', height: '10px'}}
                            ></span>{' '}
                            Test evidences
                          </span>
                          <span>0</span>
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='d-flex justify-content-between mb-2'>
                          <span>
                            <span
                              className='bg-primary rounded-circle d-inline-block me-2'
                              style={{width: '10px', height: '10px'}}
                            ></span>{' '}
                            Controls
                          </span>
                          <span>{audit.TotalControls ? audit.TotalControls : 0}</span>
                        </div>
                        <div className='d-flex justify-content-between mb-2'>
                          <span>
                            <span
                              className='bg-success rounded-circle d-inline-block me-2'
                              style={{width: '10px', height: '10px'}}
                            ></span>{' '}
                            Tests
                          </span>
                          <span>{audit.TotalTests ? audit.TotalTests : 0}</span>
                        </div>
                        <div className='d-flex justify-content-between mb-2'>
                          <span>
                            <span
                              className='bg-success rounded-circle d-inline-block me-2'
                              style={{width: '10px', height: '10px'}}
                            ></span>{' '}
                            Employees
                          </span>
                          <span>{audit.TotalEmployees ? audit.TotalEmployees : 0}</span>
                        </div>
                        <div className='d-flex justify-content-between mb-2'>
                          <span>
                            <span
                              className='bg-success rounded-circle d-inline-block me-2'
                              style={{width: '10px', height: '10px'}}
                            ></span>{' '}
                            Risk Management
                          </span>
                          <span>{audit.TotalRisks ? audit.TotalRisks : 0}</span>
                        </div>
                        <div className='d-flex justify-content-between mb-2'>
                          <span>
                            <span
                              className='bg-success rounded-circle d-inline-block me-2'
                              style={{width: '10px', height: '10px'}}
                            ></span>{' '}
                            Asset Management
                          </span>
                          <span>{audit.TotalAssets ? audit.TotalAssets : 0}</span>
                        </div>
                        <div className='d-flex justify-content-between mb-2'>
                          <span>
                            <span
                              className='bg-success rounded-circle d-inline-block me-2'
                              style={{width: '10px', height: '10px'}}
                            ></span>{' '}
                            Vendor Risk
                          </span>
                          <span>{audit.TotalVendors ? audit.TotalVendors : 0}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
              <Card.Footer className='px-6 pb-4 pt-0 border-0 border-start border-end border-bottom'>
                <input
                  type='text'
                  className='form-control'
                  placeholder='The audit report is not yet available.'
                  value={audit.audit_documents ? audit.audit_documents[0].fileName : ''}
                  // onClick={() => triggerFileInputClick(audit.id)}
                  readOnly
                />
              </Card.Footer>
            </Card>
          )
        })
      ) : (
        <>
          {searchLength ? (
            <div
              className='d-flex justify-content-center align-items-center text-center'
              style={{height: '60%'}}
            >
              <div className='m-20'>
                <img src={toAbsoluteUrl('/media/buttons/emptystate.svg')} alt='' />
                <div className='m-5 fs-5'>
                  <p style={{fontWeight: 'bold'}}>No Audit Found</p>
                </div>
                <p>
                  We couldn't find any audits that match your search. Maybe try a different search!
                </p>
              </div>
            </div>
          ) : (
            <div
              className='d-flex justify-content-center align-items-center text-center'
              style={{height: '60%'}}
            >
              {!showEmptyState ? (
                <AuditListLoading />
              ) : (
                <div className=''>
                  <img src={toAbsoluteUrl('/media/buttons/emptystate.svg')} alt='' />
                  <div className='m-5 fs-5'>
                    <p style={{fontWeight: 'bold'}}>No Audits Completed</p>
                  </div>
                  <p>When the audits are completed it will be displayed here.</p>
                </div>
              )}
            </div>
          )}
        </>
      )}
      <ToastContainer />
      <RequestAccess
        isOpen={showCompleteModal}
        onClose={() => setShowCompleteModal(false)}
        id={currentAuditId}
        audit={auditprop}
        onComplete={() => {
          setShowCompleteModal(false)
        }}
      />
    </>
  )
}

export default AuditComplete
