/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div
        className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-5 order-2 order-lg-1 '
        style={{maxHeight: '100vh', overflowY: 'auto'}}
      >
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          <a href='/' className='form-logo w-inline-block mb-4'>
            {' '}
            <img alt='Logo' src={toAbsoluteUrl('/Secureslate_logos/Secureslate_dark.png')} className='h-40px' />
          </a>
          {/* begin::Wrapper */}
          <div className='w-lg-500px '>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}
        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap pt-10 '>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a
              href='https://getsecureslate.com/policy#terms'
              className='px-5'
              target='_blank'
              rel='noreferrer'
              style={{color: '#31bd93'}}
            >
              Terms
            </a>

            <a
              href='https://getsecureslate.com/pricing'
              className='px-5'
              target='_blank'
              rel='noreferrer'
              style={{color: '#31bd93'}}
            >
              Plans
            </a>

            <a
              href='https://getsecureslate.com/contact'
              className='px-5'
              target='_blank'
              rel='noreferrer'
              style={{color: '#31bd93'}}
            >
              Contact Us
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-contain bgi-position-center order-1 order-lg-2'
        style={{backgroundColor: '#1f1f1f'}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/' className='mb-0 mt-6 mb-lg-12'>
            <img alt='Logo' src={toAbsoluteUrl('/Secureslate_logos/logo.png')} className='h-50px' />
          </Link>
          {/* end::Logo */}
          {/* begin::Image */}
          <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20 d-none d-sm-block'
            src={toAbsoluteUrl('/media/svg/illustrations/iso-27001-headerimage.svg')}
            alt=''
          />
          {/* end::Image */}-{/* begin::Title */}
          <h3 className='text-white fs-2qx fw-bolder text-center mb-7 d-none d-sm-block'>
            The world's most cutting-edge platform for automating compliance
          </h3>
          {/* end::Title */}
          {/* begin::Text */}
          <div className='fs-lg-3 text-gray-400 d-none d-sm-block'>
            ISO 27001, SOC 2, PCI DSS, HIPAA, GDPR, CCPA, and more.
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
