/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import {collection, doc, onSnapshot, updateDoc, writeBatch} from 'firebase/firestore'
import ReactPaginate from 'react-paginate'
import './pagination.css'
import {Helmet} from 'react-helmet'
import {ViewTests} from './components/ViewTests'
import {TestListLoading} from './components/loading/TestListLoading'
import {useAuth} from '../../modules/auth'
import {useLocation} from 'react-router-dom'
import Swal from 'sweetalert2'
import {TestListToolbar} from './components/header/TestListToolbar'
import {Overlay, Popover} from 'react-bootstrap'
import useCrispChat from 'app/Hooks/useCrispChat'

type Props = {
  className: string
  tests: any[]
}

const TestManagement: React.FC<Props> = ({tests}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [testID, setTestID] = useState('')
  const [selectedOwner, setSelectedOwner] = useState('')
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [searchLength, setSearchLength] = useState(false)
  const {currentUser} = useAuth()
  const [selectedNumber, setSelectedNumber] = useState('50')
  let ITEMS_PER_PAGE = parseInt(selectedNumber)
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const id = searchParams.get('id')
  const [show1, setShow1] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined)
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([])
  const overlayRef = useRef<HTMLDivElement>(null)
  const [personnels, setPersonnels] = useState<any[]>([])
  const target = useRef(null)
  const {toggleCrispChat} = useCrispChat()
  const [activeRowId, setActiveRowId] = useState("");

  useEffect(() => {
    getActiveFramworks()
  }, [])

  useEffect(() => {
    if (currentUser?.account_type === 'Trial') {
      const maskedTests = tests.map((test) => ({
        ...test,
        name: maskStringPreservingSpaces(test.name),
      }))
      setFilteredItems(maskedTests)
    } else {
      setFilteredItems(tests)
    }
  }, [tests])

  useEffect(() => {
    if (activeFrameworks.length > 0) {
      // First, sort and filter tests based on active frameworks
      const sortedAndFilteredTests = tests
        .filter((test) => {
          const testFrameworks = test.framework.split(',').map((fw) => fw.trim())
          return activeFrameworks.some((activeFramework) =>
            testFrameworks.some((testFramework) => testFramework === activeFramework)
          )
        })
        .sort((a, b) => {
          const policyA = a.name || ''
          const policyB = b.name || ''
          return policyA.localeCompare(policyB)
        })
      const processedTests = sortedAndFilteredTests.map((test, index) => {
        if (currentUser?.account_type === 'Trial' && index >= 5) {
          return {
            ...test,
            name: maskStringPreservingSpaces(test.name),
            framework: '******',
            category: '****',
            timeframe: '*****',
            owner: '',
            next_review_date: '',
          }
        } else {
          return test
        }
      })
      setFilteredItems(processedTests)
    } else {
      setFilteredItems([])
    }
  }, [tests, activeFrameworks, currentUser])

  useEffect(() => {
    if (id) {
      handleOpenModal(id)
    }
  }, [id])

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'employees')

    // const limitedQuery = query(controlsCollectionRef, limit(10))

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      const risksData = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }))
      setPersonnels(risksData)
    })

    return unsubscribe
  }, [])

  const maskStringPreservingSpaces = (str, visibleStartLength = 5, visibleEndLength = 4) => {
    if (!str || str.length <= visibleStartLength + visibleEndLength) return '****'

    const start = str.slice(0, visibleStartLength)
    const end = str.slice(-visibleEndLength)
    const middle = str.slice(visibleStartLength, -visibleEndLength)

    const maskedMiddle = [...middle].map((char) => (char === ' ' ? ' ' : '*')).join('')
    return `${start}${maskedMiddle}${end}`
  }

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)

      const testsCollectionRef = collection(tenantRef, 'active_frameworks')

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }))
        const frameworks = frameworksData[0]?.frameworks || []
        setActiveFrameworks(frameworks)
      })

      return unsubscribe // Return the unsubscribe function
    } catch (error) {
      console.error('Error getting tests:', error)
    }
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (overlayRef.current && !overlayRef.current.contains(event.target as Node) && show1) {
        setShow1(false)
      }
    }

    if (show1) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [show1])

  const updateTestStatus = (test: any) => {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    if (test.next_review_date) {
      const reviewDate = new Date(test.next_review_date)
      reviewDate.setHours(0, 0, 0, 0)

      if (reviewDate <= today && test.status !== 'Fail') {
        const tenantId = currentUser?.tenantId || ''
        if (tenantId) {
          const tenantRef = doc(db, 'tenants', tenantId)
          const testRef = doc(tenantRef, 'tests', test.id)
          updateDoc(testRef, {status: 'Fail'})
        }
        return {...test, status: 'Fail'}
      }
    }
    return test
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    if (searchTerm === '') {
      setSearchLength(false)
      // Reset to initial state which respects active frameworks
      const initialFilter = tests
        .map(updateTestStatus)
        .filter((test) => {
          const testFrameworks = test.framework.split(',').map((fw) => fw.trim())
          return activeFrameworks.some((activeFramework) =>
            testFrameworks.some((testFramework) => testFramework === activeFramework)
          )
        })
        .sort((a, b) => {
          const policyA = a.name || ''
          const policyB = b.name || ''
          return policyA.localeCompare(policyB)
        })
      setFilteredItems(initialFilter)
    } else if (currentUser?.account_type === 'Trial') {
      // For Trial accounts, filter only within the first 5 items
      const filteredBySearchTerm = filteredItems
        .slice(0, 5)
        .filter((test) => test.name.toLowerCase().includes(searchTerm))
      setFilteredItems(filteredBySearchTerm)
      setSearchLength(filteredBySearchTerm.length === 0)
    } else {
      const filteredBySearchTerm = filteredItems.filter((test) =>
        test.name.toLowerCase().includes(searchTerm)
      )
      // Further filter by active frameworks
      const filteredByFrameworks = filteredBySearchTerm.filter((test) => {
        const testFrameworks = test.framework.split(',').map((fw) => fw.trim())
        return activeFrameworks.some((activeFramework) =>
          testFrameworks.some((testFramework) => testFramework === activeFramework)
        )
      })
      setFilteredItems(filteredByFrameworks)
      setSearchLength(filteredByFrameworks.length === 0)
    }
  }

  const pageCount = Math.ceil(filteredItems.length / ITEMS_PER_PAGE)

  const handlePageClick = ({selected}: {selected: number}) => {
    setCurrentPage(selected)
  }

  const offset = currentPage * ITEMS_PER_PAGE

  const paginatedData = filteredItems.slice(offset, offset + ITEMS_PER_PAGE)

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    setTestID(id)
    setActiveRowId(id)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setActiveRowId('')
  }

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked
    setSelectAll(isChecked)
    setCheckedItems(isChecked ? filteredItems.map((item) => item.id) : [])
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => {
      const newCheckedItems = checked ? [...prev, value] : prev.filter((item) => item !== value)

      // Update selectAll state based on whether all items are checked
      setSelectAll(newCheckedItems.length === tests.length)

      return newCheckedItems
    })
  }

  const handleAssignOwner = async (e: React.FormEvent) => {
    e.preventDefault()
    if (!selectedOwner) {
      return
    }

    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) return

    const tenantRef = doc(db, 'tenants', tenantId)
    const batch = writeBatch(db)

    for (const testId of checkedItems) {
      const testRef = doc(tenantRef, 'tests', testId)
      batch.update(testRef, {owner: selectedOwner})
    }

    try {
      await batch.commit()
      Swal.fire({
        icon: 'success',
        title: 'Updated owner',
        text: ` "Successfully assigned ${selectedOwner} to ${checkedItems.length} test(s)`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
      setShow1(false)
      setCheckedItems([])
      setSelectAll(false)
      setSelectedOwner('')
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'Failed to assign owner. Please try again.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  function formatDate(dateString) {
    if (!dateString) return '--'

    const date = new Date(dateString)
    const day = date.getDate()
    const month = date.toLocaleString('default', {month: 'long'})
    const year = date.getFullYear()

    return `${day} ${month}, ${year}`
  }

  const handleResetClick = () => {
    setSelectedOwner('')
  }
  return (
    <>
      <Helmet>
        <title>SecureSlate | Tests</title>
      </Helmet>

      <div className='card-header border-0 pt-6 h-100 mb-1'>
        <div className='card-title d-flex justify-content-between align-items-center'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-3' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control ps-12'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='card-toolbar'>
          {checkedItems.length > 0 ? (
            <>
              {checkedItems.length > 0 ? (
                <div className='  border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                  <span>
                    <p className='pt-4'>{checkedItems.length} Selected</p>
                  </span>
                </div>
              ) : null}
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                  <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                </div>

                <div className='mb-1' style={{cursor: 'pointer'}}>
                  <button
                    className='btn btn-sm fs-6'
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                    ref={target}
                    onClick={() => setShow1(!show1)}
                    style={{
                      backgroundColor: '#1CBB8D',
                      color: '#fff',

                      height: '40px',
                      borderRadius: '4px',
                      left: '615px',
                      top: '20px',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    {' '}
                    Assign Owner
                  </button>
                </div>
                <Overlay
                  show={show1}
                  target={target.current}
                  placement='bottom'
                  container={document.body}
                  containerPadding={10}
                >
                  {(props) => (
                    <Popover id='popover-contained' {...props}>
                      <div ref={overlayRef as React.RefObject<HTMLDivElement>}>
                        {/* <Popover.Header as='h3' className='fw-bold fs-5'>
                      Assign Owner
                    </Popover.Header> */}
                        <Popover.Body onClick={(e) => e.stopPropagation()}>
                          <form onSubmit={handleAssignOwner}>
                            <div className='mb-3'>
                              {/* <label htmlFor='owner'>Owner</label> */}
                              <div className='mb-3 d-flex align-items-center'>
                                <select
                                  id='owner'
                                  className='form-select form-control'
                                  aria-label='Select Owner'
                                  value={selectedOwner}
                                  onChange={(e) => setSelectedOwner(e.target.value)}
                                >
                                  <option value='' disabled selected>
                                    Select Owner
                                  </option>
                                  {personnels
                                    .filter((user) => user.role === 'Admin')
                                    .map((personnel) => (
                                      <option key={personnel.id} value={personnel.full_name}>
                                        {personnel.full_name}
                                      </option>
                                    ))}
                                </select>
                                {selectedOwner && (
                                  <button
                                    className='btn btn-link p-0'
                                    onClick={() => setSelectedOwner('')}
                                  >
                                    <span aria-hidden='true' className='mx-2 fw-bold fs-2'>
                                      &times;
                                    </span>
                                  </button>
                                )}
                              </div>
                            </div>
                            <button
                              type='button'
                              onClick={handleResetClick}
                              className='btn btn-sm fs-6'
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#f1fdf9')
                              }
                              style={{
                                backgroundColor: '#f1fdf9',
                                color: '#45dbaf',
                                width: '80px',
                                // height: '40px',
                                borderRadius: '4px',
                                left: '615px',
                                top: '20px',
                                transition: 'background-color 0.3s',
                                marginRight: '12px',
                              }}
                            >
                              Reset
                            </button>
                            <button
                              type='submit'
                              className='btn btn-sm fs-6'
                              onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              style={{
                                backgroundColor: '#1CBB8D',
                                color: '#fff',
                                width: '80px',
                                // height: '40px',
                                borderRadius: '4px',
                                left: '615px',
                                top: '20px',
                                transition: 'background-color 0.3s',
                              }}
                            >
                              Apply
                            </button>
                          </form>
                        </Popover.Body>
                      </div>
                    </Popover>
                  )}
                </Overlay>
              </div>
            </>
          ) : (
            <>
              <TestListToolbar />
            </>
          )}
        </div>

        {/* end::Group actions */}
      </div>

      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
                <th className='w-25px'>
                  {currentUser?.account_type !== 'Trial' && (
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        checked={selectAll}
                        data-kt-check='true'
                        data-kt-check-target='.widget-9-check'
                        onChange={handleSelectAllChange}
                      />
                    </div>
                  )}
                </th>

                <th className='min-w-300px'>NAME</th>
                <th className='min-w-50px'>OWNER</th>
                {/* <th className='min-w-100px'>STATUS</th> */}
                <th className='min-w-100px'>TYPE</th>
                <th className='min-w-100px'>CATEGORY</th>
                <th className='min-w-100px'>NEXT REVIEW</th>
                <th className='min-w-50px'>TEST INTERVAL</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <ViewTests isOpen={isModalOpen} onClose={handleCloseModal} id={testID} />

            {paginatedData && paginatedData.length > 0 ? (
              paginatedData.map((testsData, index) => {
                if (index < 5) {
                  return (
                    <tbody>
                      <tr
                        key={testsData.id}
                        className={`${activeRowId === testsData.id ? 'bg-gray-100' : ''}`}
                        style={
                          currentUser?.account_type === 'Trial' && index >= 5
                            ? {filter: 'blur(4px)', pointerEvents: 'none'}
                            : {}
                        }
                      >
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value={testsData.id}
                              checked={selectAll || checkedItems.includes(testsData.id)}
                              onChange={handleCheckChange}
                            />
                          </div>
                        </td>

                        <td>
                          <div className='d-flex flex-column'>
                            <a
                              href='#'
                              onClick={(e) =>
                                {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleOpenModal(testsData.id)
                                }}
                              className='text-dark fw-bold text-hover-primary d-block fs-6 mb-1'
                            >
                              {' '}
                              {testsData.name}
                            </a>
                            <span>
                              <a
                                href='#'
                                className='badge fs-7'
                                style={{
                                  color: testsData.status === 'Pass' ? '#1CBB8D' : '#EE3043',
                                  background: testsData.status === 'Pass' ? '#E8F8F4' : '#FFF5F8',
                                }}
                              >
                                {' '}
                                {testsData.status === 'Pass' ? 'Passing' : 'Failing'}
                              </a>

                              <span className='fs-7 fw-semibold mx-2'>
                                {testsData.framework
                                  .split(', ')
                                  .filter((framework) =>
                                    activeFrameworks.some((item) => framework.startsWith(item))
                                  )
                                  .map((framework, index, array) => (
                                    <React.Fragment key={index}>
                                      {framework}
                                      {index < array.length - 1 && ', '}
                                    </React.Fragment>
                                  ))}
                              </span>
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            {testsData.owner ? (
                              <>
                                <div className='symbol symbol-35px me-5'>
                                  {testsData.owner ? (
                                    avatarUrl ? (
                                      <img alt='logo' loading='lazy' src={avatarUrl} />
                                    ) : (
                                      <div className='symbol symbol-25px'>
                                        <span
                                          className='symbol-label fs-7 fw-bold'
                                          style={{backgroundColor: '#DAF9EF'}}
                                        >
                                          {testsData.owner
                                            ? (() => {
                                                const nameParts = testsData.owner.split(' ')
                                                if (nameParts.length > 1) {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[1]
                                                    .charAt(0)
                                                    .toUpperCase()}`
                                                } else {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[0]
                                                    .charAt(1)
                                                    .toUpperCase()}`
                                                }
                                              })()
                                            : ''}
                                        </span>
                                      </div>
                                    )
                                  ) : (
                                    <>-</>
                                  )}
                                </div>
                                {/* <div className='d-flex justify-content-start flex-column'>
                                <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                                  {testsData.owner}
                                </a>
                              </div> */}
                              </>
                            ) : (
                              <>--</>
                            )}
                          </div>
                        </td>
                        <td>
                          <a
                            href='#'
                            className='badge fs-7 text-dark text-bold m-1'
                            style={{background: '#F6F6F6'}}
                          >
                            {testsData.type}
                          </a>
                        </td>

                        <td>
                          <div className='fs-6 fw-semibold'>{testsData.category}</div>
                        </td>
                        <td>
                          {' '}
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2 fw-semibold fs-6'>
                              {testsData.next_review_date ? (
                                formatDate(testsData.next_review_date)
                              ) : (
                                <>--</>
                              )}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <a href='#' className='text-dark fw-semibold ml-5 fs-6'>
                                {testsData.timeframe ? testsData.timeframe : '--'}
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )
                } else if (currentUser?.account_type === 'Trial' && index === 5) {
                  return (
                    <tbody key='message-row' className=''>
                      <tr>
                        <td colSpan={100}>
                          <div className='ms-4 text-center fw-semibold'>
                            To unlock full access and view all data, please upgrade your plan.{' '}
                            <button
                              className='btn btn-sm fs-6 ms-2'
                              style={{background: '#1CBB8D', color: '#fff'}}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor = '#148363')
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor = '#1CBB8D')
                              }
                              onClick={toggleCrispChat}
                            >
                              Contact sales
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )
                } else {
                  return (
                    <tbody>
                      <tr
                        key={testsData.id}
                        className={`${activeRowId === testsData.id ? 'bg-gray-100' : ''}`}
                        style={
                          currentUser?.account_type === 'Trial' && index >= 5
                            ? {filter: 'blur(4px)', pointerEvents: 'none'}
                            : {}
                        }
                      >
                        <td>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input widget-9-check'
                              type='checkbox'
                              value={testsData.id}
                              checked={selectAll || checkedItems.includes(testsData.id)}
                              onChange={handleCheckChange}
                            />
                          </div>
                        </td>

                        <td>
                          <div className='d-flex flex-column'>
                            <a
                              href='#'
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  if (currentUser?.account_type !== 'Trial') {
                                    handleOpenModal(testsData.id)
                                  }
                                }}
                              
                              className='text-dark fw-bold text-hover-primary d-block fs-6 mb-1'
                            >
                              {' '}
                              {testsData.name}
                            </a>
                            <span>
                              <a
                                href='#'
                                className='badge fs-7'
                                style={{
                                  color: testsData.status === 'Pass' ? '#1CBB8D' : '#EE3043',
                                  background: testsData.status === 'Pass' ? '#E8F8F4' : '#FFF5F8',
                                }}
                              >
                                {' '}
                                {testsData.status === 'Pass' ? 'Passing' : 'Failing'}
                              </a>

                              <span className='fs-7 fw-semibold mx-2'>
                                {testsData.framework
                                  .split(', ')
                                  .filter((framework) =>
                                    activeFrameworks.some((item) => framework.startsWith(item))
                                  )
                                  .map((framework, index, array) => (
                                    <React.Fragment key={index}>
                                      {framework}
                                      {index < array.length - 1 && ', '}
                                    </React.Fragment>
                                  ))}
                              </span>

                              {/* {showAllFrameworks && activeFrameworks.length > 1 && (
                            <>
                              {activeFrameworks.slice(1).map((framework, index) => (
                                <>
                              
                                  {'.'}{' '}
                                  <span key={index} className='mx-1 fs-7 fw-semibold'>
                                 
                                    {framework.startsWith('ISO')
                                      ? 'ISO 27001:2022'
                                      : framework.startsWith('SOC')
                                      ? 'SOC 2'
                                      : framework}
                                  </span>
                                
                                </>
                              ))}
                            </>
                          )} */}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            {testsData.owner ? (
                              <>
                                <div className='symbol symbol-35px me-5'>
                                  {testsData.owner ? (
                                    avatarUrl ? (
                                      <img alt='logo' loading='lazy' src={avatarUrl} />
                                    ) : (
                                      <div className='symbol symbol-25px'>
                                        <span
                                          className='symbol-label fs-7 fw-bold'
                                          style={{backgroundColor: '#DAF9EF'}}
                                        >
                                          {testsData.owner
                                            ? (() => {
                                                const nameParts = testsData.owner.split(' ')
                                                if (nameParts.length > 1) {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[1]
                                                    .charAt(0)
                                                    .toUpperCase()}`
                                                } else {
                                                  return `${nameParts[0]
                                                    .charAt(0)
                                                    .toUpperCase()}${nameParts[0]
                                                    .charAt(1)
                                                    .toUpperCase()}`
                                                }
                                              })()
                                            : ''}
                                        </span>
                                      </div>
                                    )
                                  ) : (
                                    <>-</>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>--</>
                            )}
                          </div>
                        </td>

                        <td>
                          <a
                            href='#'
                            className='badge fs-7 text-dark text-bold m-1'
                            style={{background: '#F6F6F6'}}
                          >
                            {testsData.type}
                          </a>
                        </td>

                        <td>
                          <div className='fs-6 fw-semibold'>{testsData.category}</div>
                        </td>
                        <td>
                          {' '}
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2 fw-semibold fs-6'>
                              {testsData.next_review_date ? (
                                formatDate(testsData.next_review_date)
                              ) : (
                                <>--</>
                              )}
                            </div>
                          </div>
                        </td>

                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <a href='#' className='text-dark fw-semibold ml-5 fs-6'>
                                {testsData.timeframe ? testsData.timeframe : '--'}
                              </a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  )
                }
              })
            ) : (
              <>
                <tbody>
                  <tr>
                    {filteredItems.length <= 0 ? (
                      <>
                        <td colSpan={10} className='text-center'>
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No tests found</p>
                            </div>
                            <p className=''>
                              It seems your account is not assigned to any framework. Contact Sales
                              to get access to framework.
                            </p>
                          </div>
                        </td>
                      </>
                    ) : searchLength ? (
                      <td colSpan={10} className='text-center'>
                        <div className='m-20'>
                          <img src='/media/buttons/emptystate.svg' alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No tests found</p>
                          </div>
                          <p className=''>
                            We couldn't find any tests that match your search. Maybe try a different
                            search!
                          </p>
                        </div>
                      </td>
                    ) : (
                      <td colSpan={10} className='text-center'>
                        {!showEmptyState ? (
                          // <td colSpan={10}>
                          <div
                            className='d-flex justify-content-center align-items-center text-center'
                            style={{height: '500px'}}
                          >
                            <TestListLoading />
                          </div>
                        ) : (
                          <div className='m-20'>
                            <img src='/media/buttons/emptystate.svg' alt='' />
                            <div className='m-5 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No filter results</p>
                            </div>
                            <p>Maybe try a different filter or reset filters</p>
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                </tbody>
              </>
            )}

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}

      {tests.length >= 15 && (
        <div className='d-flex justify-content-between'>
          <div
            id=''
            className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
          >
            <div>
              <select
                name='kt_permissions_table_length'
                aria-controls='kt_permissions_table'
                className='form-select  mx-8'
                id='dt-length-1'
                value={selectedNumber}
                onChange={(e) => setSelectedNumber(e.target.value)}
              >
                <option value='20'>20</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
              {/* <label for='dt-length-1'></label> */}
            </div>
          </div>

          <div className='d-flex justify-content-end my-10'>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              activeClassName={'active'}
            />
          </div>
        </div>
      )}
    </>
  )
}

export {TestManagement}
