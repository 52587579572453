import React, { useEffect, useState, useMemo } from 'react';
import { OnDashPageTitle } from '../cores/OnDashPageData';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { getDownloadURL, listAll, ref } from 'firebase/storage';
import db, { storage } from '../../../services/firebase';
import { useAuth } from '../../modules/auth';
import { Link, useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc } from 'firebase/firestore';

interface CustomFile {
  name: string;
  downloadURL: string;
  isChecked?: boolean;
}

const SAT = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const [selectedFileIndex, setSelectedFileIndex] = useState(0);
  const [fileData, setFileData] = useState<CustomFile[]>([]);
  const [selectedPDFUrl, setSelectedPDFUrl] = useState<string | null>(null);
  const { currentUser } = useAuth();
  const [completed, setCompleted] = useState(false);
  const navigate = useNavigate();
  const [lastPage, setLastPage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const workerUrl = useMemo(
    () => 'https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js',
    []
  );

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const folderRef = ref(storage, '/SAT_training_pdf/');
        const fileList = await listAll(folderRef);
        const filesData = await Promise.all(
          fileList.items.map(async (file) => ({
            name: file.name,
            downloadURL: await getDownloadURL(file),
          }))
        );
        setFileData(filesData);
      } catch (error) {
        console.error('Error fetching files:', error);
        setError('Failed to load files');
      }
    };
    fetchFiles();
  }, []);

  // Fetch training status
  useEffect(() => {
    const fetchTrainingStatus = async () => {
      if (!currentUser?.tenantId || !currentUser?.uid) return;
      try {
        const trainingDoc = await getDoc(
          doc(db, 'tenants', currentUser.tenantId, 'onboarding', currentUser.uid, 'training', 'SAT')
        );
        setCompleted(trainingDoc.data()?.is_checked || false);
      } catch (error) {
        console.error('Error fetching training status:', error);
      }
    };
    fetchTrainingStatus();
  }, [currentUser]);

  // Load PDF when selectedFileIndex changes
  useEffect(() => {
    if (!fileData.length || !fileData[selectedFileIndex]) return;

    setSelectedPDFUrl(null); // Reset URL to show loading state
    setError(null);

    // Use the URL directly instead of streaming manually
    setSelectedPDFUrl(fileData[selectedFileIndex].downloadURL);
  }, [fileData, selectedFileIndex]);

  const handleNameClick = (index: number) => {
    setSelectedFileIndex(index);
  };

  const handleCheckboxChange = async (index: number) => {
    if (!currentUser?.tenantId || !currentUser?.uid || isLoading) return;

    setIsLoading(true);
    try {
      const selectedFile = fileData[index];
      await setDoc(
        doc(db, 'tenants', currentUser.tenantId, 'onboarding', currentUser.uid, 'training', 'SAT'),
        {
          download_url: selectedFile.downloadURL,
          is_checked: true,
          name: selectedFile.name,
        },
        { merge: true }
      );
      setCompleted(true);
      setTimeout(() => navigate('/training'), 500);
    } catch (error) {
      console.error('Error updating training:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (e) => {
    setLastPage(e.currentPage + 1 === e.doc.numPages);
  };

  return (
    <>
      <div className="d-flex align-items-center mb-2 mt-0">
        <Link to="/training">
          <img src={toAbsoluteUrl('/media/logos/leftButton.svg')} alt="back" className="p-2" />
        </Link>
        <Link to="/training" className="text-decoration-none text-inherit">
          <label className="cursor-pointer text-black">
            <strong>Back To Training</strong>
          </label>
        </Link>
      </div>

      <OnDashPageTitle
        icon="secu.svg"
        title="Security Awareness Training"
        desc="Safeguard your digital workspace."
      />

      <div className="card">
        <div className="row">
          <div className="col-md-12">
            <div className="row m-0">
              <div className="col-md-3 border-end h-100" style={{ minHeight: '500px' }}>
                <h2 className="text-dark mb-1 mt-3 ml-4 p-4">Contents</h2>
                <hr />
                {fileData.map((file, index) => (
                  <div
                    key={index}
                    className={`d-flex align-items-start mb-2 p-3 ${
                      selectedFileIndex === index ? 'bg-light cursor-pointer' : ''
                    }`}
                    onClick={() => handleNameClick(index)}
                  >
                    <input
                      type="checkbox"
                      className="mt-2 form-check-input p-2"
                      checked={completed || file.isChecked}
                      onChange={() => handleCheckboxChange(index)}
                      disabled={completed || isLoading}
                    />
                    <div className="ms-4 cursor-pointer">
                      <div className="text-dark fw-bold text-hover-primary fs-5 mt-2">{file.name}</div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-md-9 px-0">
                <div className="mb-0">
                  <div className="overlay">
                    {error ? (
                      <div className="container-fluid">
                        <div className="row">
                          <div
                            className="col d-flex justify-content-center align-items-center"
                            style={{ height: 600 }}
                          >
                            <div className="text-danger">{error}</div>
                          </div>
                        </div>
                      </div>
                    ) : !selectedPDFUrl ? (
                      <div className="container-fluid">
                        <div className="row">
                          <div
                            className="col d-flex justify-content-center align-items-center"
                            style={{ height: 600 }}
                          >
                            <div className="d-flex flex-column align-items-center">
                              <div className="spinner-border text-primary mb-3" role="status">
                                <span className="visually-hidden">Loading...</span>
                              </div>
                              <div className="fs-6">Loading document...</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col p-0" style={{ height: 600 }}>
                            <Worker workerUrl={workerUrl}>
                              <Viewer
                                fileUrl={selectedPDFUrl}
                                plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance]}
                                onPageChange={handlePageChange}
                                defaultScale={1} // Adjust as needed
                                initialPage={0} // Start from the first page
                              />
                            </Worker>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="border p-4 ml-3">
                  {completed ? (
                    <button
                      type="button"
                      className="btn me-3 btn-sm fs-6"
                      disabled
                      style={{ backgroundColor: '#1CBB8D', color: '#fff', height: '40px' }}
                    >
                      Completed
                    </button>
                  ) : lastPage ? (
                    <button
                      type="button"
                      className="btn me-3 btn-sm fs-6"
                      style={{
                        backgroundColor: '#1CBB8D',
                        color: '#fff',
                        height: '40px',
                        minWidth: '150px',
                      }}
                      onClick={() => handleCheckboxChange(selectedFileIndex)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <>
                          Please wait...
                          <span className="spinner-border spinner-border-sm me-2" role="status" />
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M5 12l5 5l10 -10" />
                          </svg>
                          Mark as Complete
                        </>
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn me-3 btn-sm fs-6"
                      style={{ backgroundColor: '#A2E3D0', color: '#fff', height: '40px' }}
                      disabled
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="icon icon-tabler icons-tabler-outline icon-tabler-check mx-1 mb-1"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 12l5 5l10 -10" />
                      </svg>
                      Mark as Complete
                    </button>
                  )}
                  <span className="p-1 fs-6 text-muted">
                    Please note that you are required to read all the training material in order to
                    complete the training.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SAT;