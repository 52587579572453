/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import db from "../../../../../services/firebase";
import { KTIcon, toAbsoluteUrl } from "../../../../../_metronic/helpers";
import { RiskPageTitle } from "../../../../../_metronic/layout/core/RiskPageData";
import { useAuth } from "../../../../modules/auth";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RiskListLoading } from "../loading/RiskListLoading";
import useCrispChat from "app/Hooks/useCrispChat";

interface RiskTemplate {
  id: string;
  riskSource: string;
  category: string[];
  CIA: string[];
  recommendedControls: string[];
}

const RiskLibrary: React.FC = () => {
  const { currentUser } = useAuth();
  const [riskTemplates, setRiskTemplates] = useState<any[]>([]);
  const [filteredItems, setFilteredItems] = useState<any[]>([]);
  const [searchLength, setSearchLength] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const overlayRef = useRef<HTMLElement>(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false);
  const [tenantRisks, setTenantRisks] = useState<any[]>([]);
  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [activeFrameworks, setActiveFrameworks] = useState<any[]>([]);
  const { toggleCrispChat } = useCrispChat();

  useEffect(() => {
    getPolicy();
    fetchTenantRisks();
    getActiveFramworks();
  }, []);

  useEffect(() => {
    setFilteredItems(riskTemplates);
  }, [riskTemplates]);

  useEffect(() => {
    if (!isFiltered) {
      setFilteredItems(riskTemplates);
    }
  }, [riskTemplates, isFiltered]);

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  const getPolicy = () => {
    try {
      const collectionRef = collection(db, "risk_templates");

      const unsubscribe = onSnapshot(
        collectionRef,
        (snapshot) => {
          let policies = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
              riskSource: doc.data().riskSource,
            }))
            .sort((a, b) => a.riskSource.localeCompare(b.riskSource));
          if (currentUser?.account_type === "Trial") {
            policies = policies.map((risk, index) => {
              if (index >= 5) {
                return {
                  ...risk,
                  riskSource: maskStringPreservingSpaces(risk.riskSource),
                  category: "",
                  recommendedControls: "",
                };
              }
              return risk;
            });
          }
          if (currentUser?.account_type !== "") {
            setRiskTemplates(policies);
          } else {
            setRiskTemplates([]);
          }
        },
        (error) => {
          console.error("Error getting policies:", error);
        }
      );

      return unsubscribe;
    } catch (error) {
      console.error("Error setting up policy listener:", error);
    }
  };

  const getActiveFramworks = () => {
    try {
      const tenantId = currentUser?.tenantId || "";
      const tenantRef = doc(db, "tenants", tenantId);

      const testsCollectionRef = collection(tenantRef, "active_frameworks");

      const unsubscribe = onSnapshot(testsCollectionRef, (snapshot) => {
        const frameworksData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
          frameworks: doc.data().frameworks,
        }));
        const frameworks = frameworksData[0]?.frameworks || [];
        setActiveFrameworks(frameworks);
      });

      return unsubscribe; // Return the unsubscribe function
    } catch (error) {
      console.error("Error getting tests:", error);
    }
  };

  const maskStringPreservingSpaces = (
    str,
    visibleStartLength = 5,
    visibleEndLength = 4
  ) => {
    if (!str || str.length <= visibleStartLength + visibleEndLength)
      return "****";

    const start = str.slice(0, visibleStartLength);
    const end = str.slice(-visibleEndLength);
    const middle = str.slice(visibleStartLength, -visibleEndLength);

    const maskedMiddle = [...middle]
      .map((char) => (char === " " ? " " : "*"))
      .join("");

    return `${start}${maskedMiddle}${end}`;
  };

  const addRiskToTenant = async (risk: any) => {
    try {
      const tenantRef = doc(db, "tenants", currentUser?.tenantId!);
      const policyRef = collection(tenantRef, "risks");

      const docRef = await addDoc(policyRef, {
        ...risk,
        inherent_impact: 1,
        inherent_likelihood: 1,
        residual_impact: 1,
        residual_likelihood: 1,
        owner: "",
        created_at: serverTimestamp(),
        updated_at: serverTimestamp(),
        description: "",
        risk_status: "Incomplete",
      });

      return docRef.id;
    } catch (error) {
      console.error("Error adding risk:", error);
      return null;
    }
  };

  const handleAddRisk = async (risk: any) => {
    setLoadingStates((prev) => ({ ...prev, [risk.id]: true }));
    const newRiskId = await addRiskToTenant(risk);
    setLoadingStates((prev) => ({ ...prev, [risk.id]: false }));
    if (newRiskId) {
      navigate(`/risk-management/${newRiskId}`, {
        state: {
          showToast: true,
          toastMessage: `Successfully added a risk.`,
        },
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    let itemsToFilter = riskTemplates;

    if (searchTerm === "") {
      setFilteredItems(itemsToFilter);
      setSearchLength(false);
    } else if (currentUser?.account_type === "Trial") {
      const data = itemsToFilter.slice(0, 5);
      const filtered = data.filter((risk) =>
        risk.riskSource.toLowerCase().includes(searchTerm)
      );
      setFilteredItems(filtered);
      setSearchLength(filtered.length === 0);
    } else {
      const filtered = itemsToFilter.filter((risk) =>
        risk.riskSource.toLowerCase().includes(searchTerm)
      );
      setFilteredItems(filtered);
      setSearchLength(filtered.length === 0);
    }
  };
  const handleDeselectClick = () => {
    setCheckedItems([]);
    setSelectAll(false);
  };

  const fetchTenantRisks = async () => {
    try {
      const tenantRef = doc(db, "tenants", currentUser?.tenantId!);
      const risksSnapshot = await getDocs(collection(tenantRef, "risks"));
      const risks = risksSnapshot.docs.map((doc) => doc.data());
      setTenantRisks(risks);
    } catch (error) {
      console.error("Error fetching tenant risks:", error);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        overlayRef.current &&
        !overlayRef.current.contains(event.target as Node) &&
        show
      ) {
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show]);

  const handleSelectAllChange = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setCheckedItems(
        filteredItems
          // .filter((user) => user.tenantId === currentUser?.tenantId)
          .map((item) => item.id)
      );
    } else {
      setCheckedItems([]);
    }
  };

  const handleCheckChange = (e) => {
    const { checked, value } = e.target;
    setCheckedItems((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const addMultipleRisksToTenant = async (risks: any[]) => {
    const addedRiskIds: string[] = [];

    for (const risk of risks) {
      try {
        const newRiskId = await addRiskToTenant(risk);
        if (newRiskId) {
          addedRiskIds.push(newRiskId);
        }
      } catch (error) {
        console.error("Error adding risk:", error);
      }
    }

    return addedRiskIds;
  };

  const handleAddSelectedRisks = async () => {
    const selectedRisks = filteredItems.filter((risk) =>
      checkedItems.includes(risk.id)
    );
    const addedRiskIds = await addMultipleRisksToTenant(selectedRisks);

    if (addedRiskIds.length > 0) {
      // Clear the selection
      setCheckedItems([]);
      setSelectAll(false);

      navigate("/risk-management", {
        state: {
          showToast: true,
          toastMessage: `Successfully added ${addedRiskIds.length} risks.`,
        },
      });
    }
  };

  const handleResetFilter = () => {
    setSelectedCategory("All");
    setFilteredItems(riskTemplates);
    setIsFiltered(false);
    setShow(false);
  };

  const handleApplyFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value;
    if (category === "All") {
      handleResetFilter();
    } else if (currentUser?.account_type === "Trial") {
      const data = riskTemplates.slice(0, 5);
      const filtered = data.filter((risk: RiskTemplate) =>
        risk.category.includes(category)
      );
      setFilteredItems(filtered);
      setIsFiltered(true);
      setShow(false);
    } else {
      const filtered = riskTemplates.filter((risk: RiskTemplate) =>
        risk.category.includes(category)
      );
      setFilteredItems(filtered);
      setIsFiltered(true);
      setShow(false);
    }
  };

  return (
    <>
      <RiskPageTitle
        icon="personnelIcon.svg"
        title="Risk Management"
        desc={"Identify, assess, and mitigate risks to ensure compliance"}
        // breadcrumbs={usersBreadcrumbs}
      >
        Risk Management
      </RiskPageTitle>

      <div className="card card-custom ">
        <div className="card-header border-0 pt-6 h-100">
          <div className="card-title">
            {/* begin::Search */}
            <div className="d-flex align-items-center position-relative my-1">
              <KTIcon
                iconName="magnifier"
                className="fs-1 position-absolute ms-6"
              />
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control  w-250px ps-14"
                placeholder="Search.."
                value={searchTerm}
                onChange={handleInputChange}
              />
            </div>

            {/* end::Search */}
          </div>
          <div className="card-toolbar">
            {checkedItems.length > 0 ? (
              <>
                {checkedItems.length > 0 ? (
                  <div
                    className="  border-end pe-4 text-center"
                    style={{ color: "#A1A5B7" }}
                  >
                    <span>
                      <p className="pt-4">{checkedItems.length} Selected</p>
                    </span>
                  </div>
                ) : null}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="mx-5"
                    style={{ cursor: "pointer" }}
                    onClick={handleDeselectClick}
                  >
                    <img src="/media/buttons/Deselect.svg" alt="Deselect" />
                  </div>

                  <button
                    className="btn btn-sm fs-6"
                    onClick={handleAddSelectedRisks}
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = "#148363")
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = "#1CBB8D")
                    }
                    style={{
                      backgroundColor: "#1CBB8D",
                      color: "#fff",
                      width: "165px",
                      height: "40px",
                      borderRadius: "4px",
                      left: "615px",
                      top: "20px",
                      transition: "background-color 0.3s",
                    }}
                  >
                    Add Selected Risks
                  </button>
                </div>
              </>
            ) : (
              <>
                {/* <form onSubmit={handleApplyFilter}> */}

                <div className="d-flex align-items-center justify-content-end ">
                  <span className="fs-6 fw-semibold me-2">Category: </span>
                  <select
                    id="category"
                    className="form-select w-50"
                    aria-label="Select category"
                    value={selectedCategory}
                    // style={{width:'70%'}}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value);
                      if (e.target.value === "All") {
                        handleResetFilter();
                      } else {
                        handleApplyFilter(e);
                      }
                    }}
                  >
                    <option value="" disabled selected>
                      Select Category
                    </option>
                    <option onClick={handleResetFilter}>All</option>
                    <option value="Access control">Access control</option>
                    <option value="Artificial intelligence">
                      Artificial intelligence
                    </option>
                    <option value="Asset management">Asset management</option>
                    <option value="Business continuity and disaster recovery">
                      Business continuity and disaster recovery
                    </option>
                    <option value="Communications security">
                      Communications security
                    </option>
                    <option value="Compliance">Compliance</option>
                    <option value="Cryptography">Cryptography</option>
                    <option value="Environmental, social, and governance">
                      Environmental, social, and governance
                    </option>
                    <option value="Fraud">Fraud</option>
                    <option value="People operations">People operations</option>
                    <option value="Incident response manegement">
                      Incident response management
                    </option>
                    <option value="Information security policies">
                      Information security policies
                    </option>
                    <option value="Operations security">
                      Operations security
                    </option>
                    <option value="Information security operations">
                      Information security operations
                    </option>
                    <option value="Physical and environmental security">
                      Physical and environmental security
                    </option>
                    <option value="Privacy">Privacy</option>
                    <option value="Vendor relationships">
                      Vendor relationships
                    </option>
                    <option value="Software development and acquisition">
                      Software development and acquisition
                    </option>
                    <option value="Trustworthiness">Trustworthiness</option>
                  </select>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="table-responsive ps-9">
          {/* begin::Table */}
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            {/* begin::Table head */}
            <thead>
              <tr className="fw-bold text-muted fs-6">
                <th className="w-25px">
                  {currentUser?.account_type !== "Trial" && (
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={selectAll}
                        data-kt-check="true"
                        data-kt-check-target=".widget-9-check"
                        onChange={handleSelectAllChange}
                      />
                    </div>
                  )}
                </th>

                <th className="min-w-400px">SCENARIO</th>
                <th className="min-w-200px">CATEGORY</th>
                {activeFrameworks.length > 0 ? (
                  <th className="min-w-200px">MAPPED CONTROLS</th>
                ) : (
                  <></>
                )}
                <th className="min-w-150px">ACTION</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {filteredItems && filteredItems.length > 0 ? (
                filteredItems.map((risk, index) => {
                  if (index < 5) {
                    return (
                      <tr key={risk.id}>
                        <td>
                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input
                              className="form-check-input widget-9-check"
                              type="checkbox"
                              value={risk.id}
                              checked={
                                selectAll || checkedItems.includes(risk.id)
                              }
                              onChange={handleCheckChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center fw-bold fs-6 py-2">
                            {risk.riskSource}
                          </div>
                        </td>

                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                href="#"
                                className="text-dark fw-semibold text-hover-primary fs-6"
                              >
                                {risk.category.join(", ")}
                              </a>
                            </div>
                          </div>
                        </td>
                        {activeFrameworks.length > 0 ? (
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <div className="fw-semibold fs-6">
                                  {risk.recommendedControls.length} Controls{" "}
                                  {currentUser?.account_type !== "Trial" && (
                                    <OverlayTrigger
                                      placement="right"
                                      overlay={
                                        <Tooltip id="tooltip-top">
                                          <ol>
                                            {risk.recommendedControls.map(
                                              (control, index) => (
                                                <li key={index}>{control}</li>
                                              )
                                            )}
                                          </ol>
                                        </Tooltip>
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#5A5A5A"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                        <path d="M12 9h.01" />
                                        <path d="M11 12h1v4h1" />
                                      </svg>
                                    </OverlayTrigger>
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        <td>
                          <div className="d-flex align-items-center">
                            {tenantRisks.some(
                              (tenantRisk) => tenantRisk.id === risk.id
                            ) ? (
                              <button
                                className="btn btn-sm "
                                onClick={() => handleAddRisk(risk)}
                                onMouseEnter={(
                                  e: React.MouseEvent<HTMLElement>
                                ) =>
                                  (e.currentTarget.style.backgroundColor =
                                    "#148363")
                                }
                                onMouseLeave={(
                                  e: React.MouseEvent<HTMLElement>
                                ) =>
                                  (e.currentTarget.style.backgroundColor =
                                    "#1CBB8D")
                                }
                                disabled
                                style={{
                                  backgroundColor: "#E1E1E1",
                                  color: "#909090",
                                  width: "90px",
                                  height: "40px",
                                  borderRadius: "4px",
                                  left: "615px",
                                  top: "20px",
                                  transition: "background-color 0.3s",
                                }}
                              >
                                Added
                              </button>
                            ) : (
                              <>
                                {loadingStates[risk.id] ? (
                                  <button
                                    className="btn btn-sm"
                                    onClick={() => handleAddRisk(risk)}
                                    onMouseEnter={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "#148363")
                                    }
                                    onMouseLeave={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "#1CBB8D")
                                    }
                                    // disabled={activities.length === 0}
                                    style={{
                                      backgroundColor: "#1CBB8D",
                                      color: "#fff",
                                      width: "120px",
                                      height: "40px",
                                      borderRadius: "4px",
                                      left: "615px",
                                      top: "20px",
                                      transition: "background-color 0.3s",
                                    }}
                                  >
                                    Adding...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle m-2"></span>
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-sm"
                                    onClick={() => handleAddRisk(risk)}
                                    onMouseEnter={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "#148363")
                                    }
                                    onMouseLeave={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "#1CBB8D")
                                    }
                                    // disabled={activities.length === 0}
                                    style={{
                                      backgroundColor: "#1CBB8D",
                                      color: "#fff",
                                      width: "90px",
                                      height: "40px",
                                      borderRadius: "4px",
                                      left: "615px",
                                      top: "20px",
                                      transition: "background-color 0.3s",
                                    }}
                                  >
                                    Add Risk
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  } else if (
                    currentUser?.account_type === "Trial" &&
                    index === 5
                  ) {
                    return (
                      <tr>
                        <td colSpan={100}>
                          <div className="ms-4 text-center fw-semibold">
                            To unlock full access and view all data, please
                            upgrade your plan.{" "}
                            <button
                              className="btn btn-sm fs-6 ms-2"
                              style={{ background: "#1CBB8D", color: "#fff" }}
                              onMouseEnter={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "#148363")
                              }
                              onMouseLeave={(e) =>
                                (e.currentTarget.style.backgroundColor =
                                  "#1CBB8D")
                              }
                              onClick={toggleCrispChat}
                            >
                              Contact sales
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr
                        key={risk.id}
                        style={
                          currentUser?.account_type === "Trial" && index > 5
                            ? { filter: "blur(4px)", pointerEvents: "none" }
                            : {}
                        }
                      >
                        <td>
                          <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input
                              className="form-check-input widget-9-check"
                              type="checkbox"
                              value={risk.id}
                              checked={
                                selectAll || checkedItems.includes(risk.id)
                              }
                              onChange={handleCheckChange}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center fw-bold fs-6 py-2">
                            {risk.riskSource}
                          </div>
                        </td>

                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <a
                                href="#"
                                className="text-dark fw-semibold text-hover-primary fs-6"
                              >
                                {risk.category
                                  ? risk.category.join(", ")
                                  : "--"}
                              </a>
                            </div>
                          </div>
                        </td>
                        {activeFrameworks.length > 0 ? (
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="d-flex justify-content-start flex-column">
                                <div className="fw-semibold fs-6">
                                  {risk.recommendedControls.length} Controls{" "}
                                  {risk.recommendedControls ? (
                                    <OverlayTrigger
                                      placement="right"
                                      overlay={
                                        <Tooltip id="tooltip-top">
                                          <ol>
                                            {risk.recommendedControls.map(
                                              (control, index) => (
                                                <li key={index}>{control}</li>
                                              )
                                            )}
                                          </ol>
                                        </Tooltip>
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#5A5A5A"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        className="icon icon-tabler icons-tabler-outline icon-tabler-info-circle mx-2"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                        <path d="M12 9h.01" />
                                        <path d="M11 12h1v4h1" />
                                      </svg>
                                    </OverlayTrigger>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </td>
                        ) : (
                          ""
                        )}
                        <td>
                          <div className="d-flex align-items-center">
                            {tenantRisks.some(
                              (tenantRisk) => tenantRisk.id === risk.id
                            ) ? (
                              <button
                                className="btn btn-sm "
                                // onClick={() => handleAddRisk(risk)}
                                onMouseEnter={(
                                  e: React.MouseEvent<HTMLElement>
                                ) =>
                                  (e.currentTarget.style.backgroundColor =
                                    "#148363")
                                }
                                onMouseLeave={(
                                  e: React.MouseEvent<HTMLElement>
                                ) =>
                                  (e.currentTarget.style.backgroundColor =
                                    "#1CBB8D")
                                }
                                disabled
                                style={{
                                  backgroundColor: "#E1E1E1",
                                  color: "#909090",
                                  width: "90px",
                                  height: "40px",
                                  borderRadius: "4px",
                                  left: "615px",
                                  top: "20px",
                                  transition: "background-color 0.3s",
                                }}
                              >
                                Added
                              </button>
                            ) : (
                              <>
                                {loadingStates[risk.id] ? (
                                  <button
                                    className="btn btn-sm"
                                    // onClick={() => handleAddRisk(risk)}
                                    onMouseEnter={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "#148363")
                                    }
                                    onMouseLeave={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "#1CBB8D")
                                    }
                                    disabled={
                                      currentUser?.account_type === "Trial"
                                    }
                                    style={{
                                      backgroundColor: "#1CBB8D",
                                      color: "#fff",
                                      width: "120px",
                                      height: "40px",
                                      borderRadius: "4px",
                                      left: "615px",
                                      top: "20px",
                                      transition: "background-color 0.3s",
                                    }}
                                  >
                                    Adding...{" "}
                                    <span className="spinner-border spinner-border-sm align-middle m-2"></span>
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-sm"
                                    onClick={() =>
                                      currentUser?.account_type === "Trial"
                                        ? ""
                                        : handleAddRisk(risk)
                                    }
                                    onMouseEnter={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "#148363")
                                    }
                                    onMouseLeave={(
                                      e: React.MouseEvent<HTMLElement>
                                    ) =>
                                      (e.currentTarget.style.backgroundColor =
                                        "#1CBB8D")
                                    }
                                    disabled={
                                      currentUser?.account_type === "Trial"
                                    }
                                    style={{
                                      backgroundColor: "#1CBB8D",
                                      color: "#fff",
                                      width: "90px",
                                      height: "40px",
                                      borderRadius: "4px",
                                      left: "615px",
                                      top: "20px",
                                      transition: "background-color 0.3s",
                                    }}
                                  >
                                    Add Risk
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })
              ) : (
                <>
                  <tr style={{ height: "100%" }}>
                    {searchLength ? (
                      <td colSpan={10}>
                        <div
                          className="d-flex justify-content-center align-items-center text-center"
                          style={{ height: "100%" }}
                        >
                          <div className="m-20">
                            <img
                              src={toAbsoluteUrl(
                                "/media/emptystate/riskstate.svg"
                              )}
                              alt=""
                            />
                            <div className="m-5 fs-5">
                              <p style={{ fontWeight: "bold" }}>
                                No risk scenario found!
                              </p>
                            </div>
                            <p>
                              We couldn't find any risk scenario that match your
                              search. Maybe try a different search!
                            </p>
                          </div>
                        </div>
                      </td>
                    ) : (
                      <td colSpan={10}>
                        <div
                          className="d-flex text-center mt-20"
                          style={{ height: "100%" }}
                        >
                          {!showEmptyState ? (
                            <div className="m-20">
                              <RiskListLoading />
                            </div>
                          ) : (
                            <div className="m-20">
                              <img
                                src={toAbsoluteUrl(
                                  "/media/emptystate/riskstate.svg"
                                )}
                                alt=""
                              />
                              <div className="m-4 fs-5">
                                <p style={{ fontWeight: "bold" }}>
                                  No Risks Scenario
                                </p>
                              </div>
                              <p> We couldn't find any risk scenario!</p>
                            </div>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                </>
              )}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
      </div>
      {/* begin::Table container */}

      {/* begin::Body */}
    </>
  );
};

export { RiskLibrary };
