/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {useRef, useEffect} from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
} from '../../helpers'
import {useAuth} from '../../../app/modules/auth'

type Props = {
  isDrawer?: boolean
}

const bufferMessages = defaultMessages

const ChatInner: FC<Props> = ({isDrawer = false}) => {
  const initialMessages: MessageModel[] = [
    {
      user: 1,
      type: 'in',
      text: 'Hello, I am your compliance and security assistant. How can I help you streamline your compliance processes or enhance your security measures today?',
      time: new Date().toLocaleTimeString(),
    },
    ...bufferMessages,
  ]
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<MessageModel[]>(initialMessages)
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const lastMessageRef = useRef<HTMLDivElement | null>(null)
  const {currentUser} = useAuth()
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined)

  const sendMessage = async () => {
    if (message.trim() === '') {
      return
    }
    const newMessage: MessageModel = {
      user: 2,
      type: 'out',
      text: message,
      time: 'Just now',
    }
    bufferMessages.push(newMessage)
    bufferMessages.push({
      user: 1,
      type: 'in',
      text: 'Generating response...',
      time: new Date().toLocaleTimeString(),
      loading: true,
    })

    setMessages([...bufferMessages])
    toggleChatUpdateFlat(!chatUpdateFlag)
    setMessage('')

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
        body: JSON.stringify({
          model: 'gpt-4-1106-preview',
          messages: [
            {
              role: 'system',
              content: `You are an AI assistant specializing in compliance automation and security streamlining for a SaaS project. Your expertise covers ISO 27001, SOC 2, GDPR, HIPAA, and other relevant standards. Provide detailed, actionable advice to help users automate their compliance processes, protect data, and enhance overall security measures. Focus on practical solutions and best practices specific to SaaS environments.`,
            },
            {
              role: 'user',
              content: `I have a question about ${message} in the context of compliance automation and security streamlining for our SaaS project. Can you provide detailed information and guidance on this topic?`,
            },
          ],
          max_tokens: 1000,
          temperature: 0.7,
        }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      
      if (!data.choices || !data.choices[0] || !data.choices[0].message) {
        throw new Error('Unexpected response format from OpenAI API')
      }

      const messageContent = data.choices[0].message.content

      const loadingMessageIndex = bufferMessages.findIndex((message) => message.loading)

      if (loadingMessageIndex !== -1) {
        bufferMessages[loadingMessageIndex] = {
          user: 1,
          type: 'in',
          text: messageContent,
          time: new Date().toLocaleTimeString(),
          loading: false,
        }
      }

      setMessages([...bufferMessages])
      toggleChatUpdateFlat((flag) => !flag)
    } catch (error) {
      console.error('Error from OpenAI API:', error)
      
      // Update the loading message with an error
      const loadingMessageIndex = bufferMessages.findIndex((message) => message.loading)
      if (loadingMessageIndex !== -1) {
        bufferMessages[loadingMessageIndex] = {
          user: 1,
          type: 'in',
          text: 'I apologize, but I encountered an error while processing your request. Please try again later or contact support if the issue persists.',
          time: new Date().toLocaleTimeString(),
          loading: false,
        }
        setMessages([...bufferMessages])
        toggleChatUpdateFlat((flag) => !flag)
      }
    }
  }


  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }
  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [messages])

  function formatText(text: string): string {
    // Convert Markdown headings to HTML
    text = text.replace(/### (.*$)/gim, '<h3 class="ai-response-h3">$1</h3>')
      .replace(/## (.*$)/gim, '<h2 class="ai-response-h2">$1</h2>')
      .replace(/# (.*$)/gim, '<h1 class="ai-response-h1">$1</h1>')

    // Convert Markdown bullet points (both - and *) to HTML list items
    text = text.replace(/^\s*(-|\*)\s(.*)$/gim, '<li>$2</li>')

    // Wrap adjacent list items in <ul> tags
    text = text.replace(/(<li>.*<\/li>\n?)+/g, '<ul class="ai-response-ul">$&</ul>')

    // Convert Markdown numbered lists to HTML ordered lists
    text = text.replace(/^\s*(\d+\.)\s(.*)$/gim, '<li>$2</li>')

    // Wrap adjacent numbered list items in <ol> tags
    text = text.replace(/(<li>.*<\/li>\n?)+/g, (match) => {
      return match.includes('1.') ? `<ol class="ai-response-ol">${match}</ol>` : match
    })

    // Convert double asterisks to bold text
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')

    // Convert single asterisks to italic text
    text = text.replace(/\*(.*?)\*/g, '<em>$1</em>')

    // Convert line breaks to <br> tags, but not within list items
    text = text.replace(/(?<!<\/li>)\n(?!<li>)/g, '<br>')

    return text
  }

  return (
    <>
      <div
        className='card-body mb-30'
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_drawer_chat_messenger_body'}
        style={{height: 'calc(60vh - 100px)', overflow: 'auto'}}
      >
        <div
          className={clsx('scroll-y me-n5 pe-5', {'h-300px h-lg-auto': !isDrawer})}
          data-kt-element='messages'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies={
            isDrawer
              ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
          }
          data-kt-scroll-wrappers={
            isDrawer
              ? '#kt_drawer_chat_messenger_body'
              : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
          }
          data-kt-scroll-offset={isDrawer ? '0px' : '5px'}
        >
          {messages.map((message, index) => {
            // console.log('message', messages.length)
            const userInfo = userInfos[message.user]
            const state = message.type === 'in' ? 'info' : 'primary'
            const templateAttr = {}
            if (message.template) {
              Object.defineProperty(templateAttr, 'data-kt-element', {
                value: `template-${message.type}`,
              })
            }
            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
              message.type === 'in' ? 'start' : 'end'
            } mb-10`
            return (
              <div
                key={`message${index}`}
                ref={index === messages.length - 1 ? lastMessageRef : null}
                className={clsx('d-flex', contentClass, 'mb-10', {'d-none': message.template})}
                {...templateAttr}
              >
                <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${message.type === 'in' ? 'start' : 'end'}`
                  )}
                >
                  <div className='d-flex align-items-center mb-2'>
                    {message.type === 'in' ? (
                      <>
                        <div className='symbol  symbol-25px symbol-circle '>
                          <img alt='Pic' src={toAbsoluteUrl(`/Secureslate_logos/Secureslate_mini.svg`)} />
                        </div>
                        <div className='ms-3'>
                          <a
                            href='#'
                            className='fs-6 fw-bolder text-gray-900 text-hover-primary me-1'
                          >
                            {/* {userInfo.name} */}
                            SecureslateAI{' '}
                          </a>
                          <span className='text-muted fs-7 mb-1'>{message.time}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='me-3'>
                          {/* <span className='text-muted fs-7 mb-1'>{message.time}</span> */}
                          <span className='text-muted fs-7 mb-1'>{message.time}</span>
                          <a
                            href='#'
                            className='fs-6 fw-bolder text-gray-900 text-hover-primary ms-1'
                          >
                            You
                          </a>
                        </div>
                        <div className='symbol  symbol-35px symbol-circle '>
                          {/* <img alt='Pic' src={toAbsoluteUrl(`/media/${userInfo.avatar}`)} /> */}
                          {/* <img alt='Pic' src={toAbsoluteUrl(`/media/avatars/blank.png`)} /> */}
                          {avatarUrl ? (
                            <img alt='logo' loading='lazy' src={avatarUrl} />
                          ) : (
                            <div className='symbol symbol-35px'>
                              <span
                                className='symbol-label fs-6 fw-bold '
                                style={{backgroundColor: '#DAF9EF'}}
                              >
                                {currentUser
                                  ? `${currentUser?.userName
                                      ?.split(' ')[0]
                                      ?.charAt(0)
                                      .toUpperCase()}${currentUser?.userName
                                      ?.split(' ')[1]
                                      ?.charAt(0)
                                      .toUpperCase()}`
                                  : ''}
                              </span>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={clsx(
                      'p-5 rounded',
                      `bg-light-${state}`,
                      'text-dark fw-bold mw-lg-400px',
                      `text-${message.type === 'in' ? 'start' : 'end'}`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{
                      __html: message.loading ? 'AI is typing...' : formatText(message.text),
                    }}
                  ></div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div
        className='card-footer pt-4 position-sticky bottom-0 mt-auto w-100'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
        //
        style={{width: '96%', backgroundColor: 'white'}}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button>
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
      </div>
    </>
  )
}

export {ChatInner}
