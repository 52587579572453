/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet'
import db, {storage} from '../../../services/firebase'
import {ref, listAll, uploadBytes, deleteObject, updateMetadata} from 'firebase/storage'
import {useAuth} from '../../modules/auth'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import {Button, Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'
import {DataRoomLoading} from './components/loading/DataRoomLoading'
import {toast, ToastContainer} from 'react-toastify'
import JSZip from 'jszip'

type Props = {
  className: string
  isNewFolder: Boolean
  onFolderCreatedOrCancel: Function
  onSubFolderClicked: Function
  folderPath: string
  folderId: string
  fileFolderCreated: boolean
  searchTerm: string
  onSelectionChange?: (selectedItems: FileItemType[]) => void
}

type FileItemType = {
  id?: string
  name: string
  isFolder: boolean
  fileSize: number
  contentType: string | undefined
  timeCreated: string
  isChecked?: boolean
  tenantId?: string
  uid?: string
  additionalData?: any
  downloadUrl?: string
  folderName: string
  subFolder: string
  fName: string
  fname?: string
  is_folder: boolean
  time_created: string
  fileId: string
  folder_name: string
  is_file?: boolean
  parentFolder?: string
  auditID?: string
  createdAt:string
}

const Data_room: React.FC<Props> = ({
  className,
  isNewFolder,
  onFolderCreatedOrCancel,
  onSubFolderClicked,
  folderPath,
  folderId,
  fileFolderCreated,
  searchTerm,
  onSelectionChange,
}) => {
  const [showFolderCreationForm, setShowFolderCreationForm] = useState(isNewFolder)
  const [folderCreating, setFolderCreating] = useState(false)
  const [folderClicked, setFolderClicked] = useState(false)
  const [clickedFolderName, setFolderClickedName] = useState('')
  const [items, setItems] = useState<FileItemType[]>([])
  const [allItems, setAllItems] = useState<FileItemType[]>([])
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false)
  const {currentUser} = useAuth()
  const [filteredItems, setFilteredItems] = useState<FileItemType[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [newFileName, setNewFileName] = useState('')
  const [data, setData] = useState<FileItemType[]>([])
  const [isLoadingFolder, setIsLoadingFolder] = useState('')
  const [shareLink, setShareLink] = useState('')
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [show2, setShow2] = useState(false)
  const [checkboxMTF, setCheckboxMTF] = useState('')
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [editingItemId, setEditingItemId] = useState('')
  const [isFolderEmpty, setIsFolderEmpty] = useState<boolean>(false)
  const [audits, setAudits] = useState<any[]>([])

  useEffect(() => {
    setShowEmptyState(false)

    const timeoutId = setTimeout(() => {
      if (items.length === 0) {
        setShowEmptyState(true)
      }
      if (isFolderEmpty) {
        setShowEmptyState(true)
      }
    }, 3000)

    return () => clearTimeout(timeoutId)
  }, [items, isFolderEmpty])

  useEffect(() => {
    const tenantId = currentUser?.tenantId || ''
    if (!tenantId) {
      return
    }
    const tenantRef = doc(db, 'tenants', tenantId)
    const PolicyRef = collection(tenantRef, 'audits')

    const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
      let Data = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          status: doc.data()?.status,
          createdAt: doc.data().createdAt,
          auditor: doc.data().auditor,
        }))
        .filter((data) => data.status !== 'Completed')
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
        if (currentUser?.role === 'Auditor') {
          const filteredData = Data.filter((audit) => audit.auditor?.id === currentUser?.uid);
          Data = filteredData;
        }
      setAudits(Data)
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    getData()
  }, [audits])

  const getData = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId!)
      const folderCollectionRef = collection(tenantRef, 'dataroom')

      const unsubscribe = onSnapshot(folderCollectionRef, (snapshot) => {
        let policies = snapshot.docs
          .filter((item) => {
            const data = item.data()

            return data.is_folder === true || data.parentFolder === ''
          })
          .map((item) => ({
            ...item.data(),
            id: item.id,
            createdAt:item.data().createdAt
          })) as FileItemType[]
          if (currentUser?.role === 'Auditor') {
            if (audits.length > 0) {
              policies = policies.filter(
                (item) => item.auditID === audits[0].id || item.uid === currentUser?.uid
              );
            } else {
              // If the role is Auditor but there are no audits, set policies to an empty array
              policies = [];
            }
          }
        setItems(policies)
        setAllItems(
          snapshot.docs.map((item) => ({
            ...item.data(),
            id: item.id,
          })) as FileItemType[]
        )
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting policies:', error)
    }
  }

  useEffect(() => {
    if (folderClicked) {
      showFilesInFolder(folderPath)
    } else {
      setFilteredItems(items)
    }
  }, [items, searchLength, folderPath])

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredItems(items)
      setSearchLength(false)
    } else {
      const filteredItems = items.filter((t) => {
        // Check both folder_name and name properties for a match
        const taskName = typeof t.folder_name === 'string' ? t.folder_name.toLowerCase() : ''
        const fileName = typeof t.name === 'string' ? t.name.toLowerCase() : ''
        return (
          taskName.includes(searchTerm.toLowerCase()) || fileName.includes(searchTerm.toLowerCase())
        )
      })
      setFilteredItems(filteredItems)

      setSearchLength(filteredItems.length === 0)
    }
  }, [searchTerm])

  // Init copy link
  const initCopyLink = () => {
    // Select all copy link elements
    const elements = document.querySelectorAll('[data-kt-filemanger-table="copy_link"]')

    elements.forEach((el) => {
      // Define elements
      const button = el.querySelector('button')
      const generator = el.querySelector('[data-kt-filemanger-table="copy_link_generator"]')
      const result = el.querySelector('[data-kt-filemanger-table="copy_link_result"]')
      const input = el.querySelector('input')

      // Click action
      if (button && generator && result && input) {
        button.addEventListener('click', (e) => {
          e.preventDefault()

          // Reset toggle
          generator.classList.remove('d-none')
          result.classList.add('d-none')

          var linkTimeout
          clearTimeout(linkTimeout)
          linkTimeout = setTimeout(() => {
            generator.classList.add('d-none')
            result.classList.remove('d-none')
            input.select()
          }, 2000)
        })
      }
    })
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const toggleFolderCreationForm = () => {
    onFolderCreatedOrCancel()
    setShowFolderCreationForm(!showFolderCreationForm)
  }

  const createFolder = async () => {
    const inputElement = document.querySelector('input[name="new_folder_name"]')

    if (inputElement && inputElement instanceof HTMLInputElement) {
      const inputValue = inputElement.value
      if (!inputValue) {
        // alert('Please enter a folder name.')
        return
      }

      setFolderCreating(true)
      toggleFolderCreationForm()
      const tenantId = currentUser?.tenantId || ''
      const folderRef = ref(storage, `/tenants/${tenantId}/dataroom/${inputValue}/`)
      const placeholderFileRef = ref(folderRef, 'placeholder.txt')
      await uploadBytes(placeholderFileRef, new Uint8Array(0), {
        customMetadata: {
          tenantId: currentUser?.tenantId || '',
        },
      })
      const tenantRef = doc(db, 'tenants', tenantId!)
      const folderCollectionRef = collection(tenantRef, 'dataroom')
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = String(currentDate.getMonth() + 1).padStart(2, '0')
      const day = String(currentDate.getDate()).padStart(2, '0')

      const formattedDate = `${year}-${month}-${day}`
      const newFolderRef = await addDoc(folderCollectionRef, {
        fName: inputValue,
        folder_name: inputValue,
        tenantId: currentUser?.tenantId || '',
        uid: currentUser?.uid || '',
        time_created: formattedDate,
        is_folder: true,
        subFolder: `/tenants/${tenantId}/dataroom/${inputValue}/`,
      })
      const newFolderId = newFolderRef.id
      await updateMetadata(placeholderFileRef, {
        customMetadata: {
          folderId: newFolderId,
        },
      })

      const todaysDate = month + '/' + day + '/' + year
      toast.success('Folder added successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      window.scrollTo(0, 0)
      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} added a new folder '${inputValue}'`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        action: 'Folder added',
        target: 'Dataroom',
        email: currentUser?.email,
        username: currentUser?.userName,
        date: todaysDate,
      })
      setFolderCreating(false)
      toggleFolderCreationForm()

      // You can also add Firestore logic to keep track of folders
    }
  }

  useEffect(() => {
    initCopyLink() // Initialize the copy link functionality
  }, [])

  useEffect(() => {
    // Update showFolderCreationForm when isNewFolder changes
    setShowFolderCreationForm(isNewFolder)
  }, [isNewFolder])

  const onFolderClicked = (item) => {
    setFolderClicked(true)
    setFolderClickedName(item.is_folder ? item.fName : item.name)
    onSubFolderClicked(item)
    showFilesInFolder(item)
  }

  function formatDate(isoDateString) {
    const date = new Date(isoDateString)

    const day = date.getDate()
    const month = date.toLocaleString('en-US', {month: 'long'})
    const year = date.getFullYear()

    const formattedDate = `${day} ${month}, ${year}`

    return formattedDate
  }

  const showFilesInFolder = async (folderPath) => {
    setIsFolderEmpty(false)
    try {
      const files: FileItemType[] = []
      // Reference to the Firestore collection
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId!)
      const filesCollection = collection(tenantRef, 'dataroom')

      // Query to fetch files and folders inside the selected folder
      const q = query(
        filesCollection
        // where('subFolder', '>=', folderPath),
        // where('subFolder', '<', folderPath + '\uf8ff'),
      )

      const querySnapshot = await getDocs(filesCollection)

      querySnapshot.forEach((doc) => {
        const data = doc.data()
        if (data.parentFolder === folderId && data.is_folder === false) {
          // Exclude folders here
          const file = {
            name: data.name,
            isFolder: data.is_folder,
            fileSize: data.fileSize,
            contentType: data.contentType,
            time_created: data.time_created,
            downloadUrl: data.downloadUrl,
            tenantId: data.tenantId,
            subFolder: data.subFolder,
            fName: data.fName,
            folderName: data.folderName,
            folder_name: data.folder_name,
            timeCreated: data.timeCreated,
            is_folder: data.is_folder,
            fileId: data.fileId,
            createdAt:data.createdAt
          }
          files.push(file)
        }
      })

      setFilteredItems(files)
      setIsFolderEmpty(files.length === 0)
    } catch (error) {
      console.error('Error fetching files from Firestore:', error)
    } finally {
    }
  }

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    setSelectAllChecked(isChecked)
    // Update the isChecked property for each item in the items array
    const updatedItems = items.map((item) => ({
      ...item,
      isChecked: isChecked,
    }))

    setItems(updatedItems)

    // Call the callback function with the updated selected items
    if (onSelectionChange) {
      onSelectionChange(updatedItems.filter((item) => item.isChecked))
    }
  }

  const handleCheckboxChange = (index: number) => {
    const updatedItems: FileItemType[] = items.map((item, i) => ({
      ...item,
      isChecked: i === index ? !item.isChecked : item.isChecked,
    }))
    setSelectAllChecked(updatedItems.every((item) => item.isChecked))
    setItems(updatedItems)

    // Call the callback function with the updated selected items
    if (onSelectionChange) {
      onSelectionChange(updatedItems.filter((item) => item.isChecked))
    }
  }

  const handleDeleteFile = async (fileId, fileName) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${fileName} ?`,
        text: `Are you sure you want to remove ${fileName} from the dataroom? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const fileDocRef = doc(db, 'tenants', currentUser?.tenantId!, 'dataroom', fileId)
        await deleteDoc(fileDocRef)

        setItems((prevFiles) => prevFiles.filter((file) => file.id !== fileId))

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${fileName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the file.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }

  const deleteFolder = async (folderId, folderName) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${folderName} ?`,
        text: `Are you sure you want to remove ${folderName} from the dataroom? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const folderRef = ref(storage, `/tenants/${tenantId}/dataroom/${folderName}`)

        const folderItems = await listAll(folderRef)

        await Promise.all(
          folderItems.items.map(async (item) => {
            await deleteObject(item)
          })
        )

        const folderDocRef = doc(db, 'tenants', currentUser?.tenantId!, 'dataroom', folderId)
        await deleteDoc(folderDocRef)

        setItems((prevFiles) => prevFiles.filter((item) => item.id !== folderId))

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${folderName}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the folder.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      setDropdownVisible(false)
    }
  }

  const handleDownload = async (item) => {
    try {
      if (item.downloadURL || item.downloadUrl) {
        if (item.is_folder !== true) {
          const response = await fetch(item.downloadURL || item.downloadUrl)
          const fileContent = await response.blob()

          const blob = new Blob([fileContent], {type: item.contentType})

          const blobUrl = URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = blobUrl
          link.download = item.name

          document.body.appendChild(link)

          link.click()

          document.body.removeChild(link)
          URL.revokeObjectURL(blobUrl)
        }
      }
    } catch (error) {
      console.error('Error downloading file:', error)
    }
  }

  const renameFileInFirestore = async (item, fileId, newFileName, isFolder) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId!)
      const folderCollectionRef = collection(tenantRef, 'dataroom')
      const fileDocRef = doc(folderCollectionRef, fileId)

      const auditCollectionRef = collection(tenantRef, 'audits')
      const auditDocRef = doc(auditCollectionRef, item.auditID)
      if (isFolder) {
        await updateDoc(fileDocRef, {folder_name: newFileName, fName: newFileName})
        await updateDoc(auditDocRef, {auditEvidenceFolderName: newFileName})
        setEditingItemId('')
        toast.success('Folder name updated successfully', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
      } else {
        await updateDoc(fileDocRef, {name: newFileName})
        setEditingItemId('')

        toast.success('File name updated successfully', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })
      }
    } catch (error) {
      console.error('Error renaming file in Firestore:', error)
      throw error
    }
  }

  const formatFileSize = (sizeInBytes) => {
    const kb = sizeInBytes / 1024
    if (kb >= 1024) {
      const mb = kb / 1024
      return `${mb.toFixed(2)} MB`
    }
    return `${kb.toFixed(2)} KB`
  }

  // Modified folder size function
  function getFolderSize(parentId) {
    const childItems = allItems.filter((item) => item.parentFolder === parentId)
    const directFileSize = childItems
      .filter((item) => !item.is_folder)
      .reduce((sum, file) => sum + (file.fileSize || 0), 0)
    return directFileSize
  }

  const generateShareLink = (itemName) => {
    setTimeout(() => {
      // Update state to show generated link and hide loader
      // setIsLoading(false)
      setShareLink(`https://path/to/file/or/folder/${itemName}`)
    }, 2000) // Simulating 2 seconds delay
  }

  // Handle share link generation
  const handleGenerateLink = (itemName) => {
    // setIsLoading(true) // Show loader
    generateShareLink(itemName)
  }

  const handleClose2 = () => setShow2(false)
  const handleShow2 = () => setShow2(true)

  const handleCheckboxMTFChange = (index, folderName) => {
    setCheckboxMTF(index)
    setFolderClickedName(folderName)
  }

  const handleMovetofolder = (item) => {
    const docRef = doc(db, 'policy_files', item.id)
    const updateFile = {
      ...item,
      subFolder: `policy_files/${clickedFolderName}`,
    }
    updateDoc(docRef, updateFile)
    handleClose2()
  }

  const handleDownloadZip = async (dataroomFolderID, auditEvidenceFolderName) => {
    try {
      if (dataroomFolderID) {
        const filterData = allItems.filter((data) => data.parentFolder === dataroomFolderID)

        if (filterData.length > 0) {
          setIsLoadingFolder(dataroomFolderID)
          const zip = new JSZip()

          for (const fileData of filterData) {
            const {downloadUrl, name} = fileData

            if (downloadUrl) {
              try {
                const response = await fetch(downloadUrl)

                if (!response.ok) {
                  continue
                }

                const blob = await response.blob()
                zip.file(name || 'file', blob)
              } catch (error) {
                console.error('Error fetching file:', error)
              }
            }
          }

          // Generate the zip with all files added
          const zipBlob = await zip.generateAsync({type: 'blob'})
          const blobUrl = URL.createObjectURL(zipBlob)

          // Trigger download of the zip file
          const link = document.createElement('a')
          link.href = blobUrl
          link.download = `${auditEvidenceFolderName}.zip`
          link.click()

          // Clean up the blob URL
          URL.revokeObjectURL(blobUrl)
        } else {
          toast.error('No files to download.', {
            position: toast.POSITION.TOP_CENTER,
            className: 'bg-white',
            style: {
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              color: 'black',
            },
            autoClose: 3000,
          })
          setIsLoadingFolder('')
        }
      }
    } catch (error) {
      toast.error('An error occurred while downloading the report.', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          color: 'black',
        },
        autoClose: 3000,
      })
      setIsLoadingFolder('')
    } finally {
      setIsLoadingFolder('')
    }
  }

  const handleView = (item) => {
    // Check if the item has a downloadUrl
    if (item.downloadUrl) {
      let url = item.downloadUrl;
  
      // Check the file's contentType and adjust the URL if necessary
      switch (item.contentType) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // DOCX
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': // XLSX
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': // PPTX
          // Use Google Docs Viewer for Office files
          url = `https://docs.google.com/gview?url=${encodeURIComponent(item.downloadUrl)}&embedded=true`;
          break;
        // Add more cases as needed
        default:
          // For other file types, use the direct URL
          break;
      }
  
      // Open the URL in a new tab
      window.open(url, '_blank');
    } else {
      console.error('No download URL available for this item.');
    }
  }

  return (
    <>
      <Helmet>
        <title>SecureSlate | Data Room</title>
        {/*begin::Global Javascript Bundle(mandatory for all pages)*/}
        <script type='text/jsx' src='/assets/plugins/global/plugins.bundle.js'></script>
        <script type='text/jsx' src='/assets/js/scripts.bundle.js'></script>
        {/*begin::Vendors Javascript(used for this page only)*/}
        <script
          type='text/jsx'
          src='/assets/plugins/custom/datatables/datatables.bundle.js'
        ></script>
        {/*end::Vendors Javascript*/}
        {/*begin::Custom Javascript(used for this page only)*/}
        <script type='text/jsx' src='/assets/js/custom/apps/file-manager/list.js'></script>
        <script type='text/jsx' src='/assets/js/widgets.bundle.js'></script>
        <script type='text/jsx' src='/assets/js/custom/widgets.js'></script>
        <script type='text/jsx' src='/assets/js/custom/apps/chat/chat.js'></script>
        <script type='text/jsx' src='/assets/js/custom/utilities/modals/upgrade-plan.js'></script>
        <script type='text/jsx' src='/assets/js/custom/utilities/modals/create-app.js'></script>
        <script type='text/jsx' src='/assets/js/custom/utilities/modals/users-search.js'></script>
      </Helmet>

      {/* begin:: Card Body */}
      <div className='card-body'>
        {/*begin::Table header*/}
        <div className='d-flex flex-stack'>
          {/*begin::Folder path*/}
          <div className='badge badge-lg ' style={{background: '#E8F8F4'}}>
            <div className='d-flex align-items-center flex-wrap text-primary'>
              <i className='ki-duotone ki-abstract-32 fs-2 text-primary me-3'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
              <Link to='/dashboard'>SecureSlate</Link>
              <i className='ki-duotone ki-right fs-2 text-primary mx-1'></i>
              <Link
                to='/data-room'
                onClick={() => {
                  setFolderClicked(false)
                  onSubFolderClicked(false)
                  getData()
                }}
              >
                Dataroom
              </Link>

              {folderClicked ? (
                <>
                  <i className='ki-duotone ki-right fs-2 text-primary mx-1'></i> {clickedFolderName}
                </>
              ) : null}
            </div>
          </div>
          {/*end::Folder path*/}
          {/*begin::Folder Stats*/}
          <div className='badge badge-lg ' style={{background: '#1CBB8D', color: '#fff'}}>
            <span id='kt_file_manager_items_counter'>{filteredItems.length} items</span>
          </div>
          {/*end::Folder Stats*/}
        </div>
        {/*end::Table header*/}
        {/*begin::Table*/}
        <table
          id='kt_file_manager_list'
          data-kt-filemanager-table='folders'
          className='table align-middle table-row-dashed fs-6 gy-5'
        >
          <thead>
            <tr className='text-start fw-bold fs-7 text-uppercase gs-0' style={{color: '#5A5A5A'}}>
              <th className='w-10px pe-2'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    data-kt-check='true'
                    data-kt-check-target='#kt_file_manager_list .form-check-input'
                    value='0'
                    checked={selectAllChecked}
                    onChange={handleSelectAllChange}
                  />
                </div>
              </th>
              <th className='min-w-250px'>Name</th>
              <th className='min-w-10px'>Size</th>
              <th className='min-w-125px'>Created On</th>
              <th className='w-125px'></th>
            </tr>
          </thead>
          <tbody className='fw-semibold text-gray-600'>
            {/* New Folder section */}
            {showFolderCreationForm && (
              <tr id='kt_file_manager_new_folder_row' data-kt-filemanager-template='upload'>
                <td></td>
                <td
                  id='kt_file_manager_add_folder_form'
                  className='fv-row fv-plugins-bootstrap5 fv-plugins-framework fv-plugins-icon-container'
                >
                  <div className='d-flex align-items-center'>
                    <span id='kt_file_manager_folder_icon'>
                      <i className='ki-duotone ki-folder fs-2x text-primary me-4'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>
                    </span>
                    <input
                      type='text'
                      name='new_folder_name'
                      placeholder='Enter the folder name'
                      className='form-control mw-250px me-3'
                      defaultValue=''
                    />
                    <button
                      className='btn btn-icon btn-light-primary me-3'
                      id='kt_file_manager_add_folder'
                      onClick={createFolder}
                    >
                      <span className='indicator-label'>
                        <i
                          className={
                            folderCreating
                              ? 'ki-duotone ki-loading fs-2x'
                              : 'ki-duotone ki-check fs-1'
                          }
                        ></i>
                      </span>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </button>

                    <button
                      className='btn btn-icon btn-light-danger'
                      id='kt_file_manager_cancel_folder'
                      onClick={toggleFolderCreationForm}
                    >
                      <span className='indicator-label'>
                        <i className='ki-duotone ki-cross fs-1'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>{' '}
                      </span>
                      <span className='indicator-progress'>
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    </button>
                  </div>
                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            )}
            {filteredItems && filteredItems.length > 0 ? (
              filteredItems
              .sort((a, b) => b.time_created.localeCompare(a.time_created))
              .map((item, index) => (
                <tr key={item.id || item.fileId}>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        value={index}
                        checked={item.isChecked || false}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </div>
                  </td>

                  <td>
                    {editingItemId === (item.id || item.fileId) ? (
                      <div className='d-flex align-items-center'>
                        <input
                          type='text'
                          value={newFileName}
                          placeholder={item.is_folder ? item.folder_name : item.name}
                          onChange={(e) => setNewFileName(e.target.value)}
                          className='form-control mw-250px me-3'
                        />
                        <button
                          className='btn btn-icon btn-light-primary me-3'
                          onClick={() => {
                            if (item.is_folder) {
                              renameFileInFirestore(item, item.id, newFileName, item.is_folder)
                            } else
                              renameFileInFirestore(item, item.fileId, newFileName, item.is_folder)
                          }}
                        >
                          <i className='ki-duotone ki-check fs-1'></i>
                        </button>
                        <button
                          className='btn btn-icon btn-light-danger'
                          onClick={() => setEditingItemId('')}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 24 24'
                            fill='none'
                            stroke='currentColor'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                            className='icon icon-tabler icons-tabler-outline icon-tabler-x'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                            <path d='M18 6l-12 12' />
                            <path d='M6 6l12 12' />
                          </svg>
                        </button>
                      </div>
                    ) : (
                      <div className='d-flex align-items-center'>
                        <span id='kt_file_manager_folder_icon'>
                          {item.is_folder ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='22'
                              height='22'
                              viewBox='0 0 24 24'
                              fill='#1CBB8D'
                              className='icon icon-tabler icons-tabler-filled icon-tabler-folder me-2 mb-1'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M9 3a1 1 0 0 1 .608 .206l.1 .087l2.706 2.707h6.586a3 3 0 0 1 2.995 2.824l.005 .176v8a3 3 0 0 1 -2.824 2.995l-.176 .005h-14a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-11a3 3 0 0 1 2.824 -2.995l.176 -.005h4z' />
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='22'
                              height='22'
                              viewBox='0 0 24 24'
                              fill='#1CBB8D'
                              className='icon icon-tabler icons-tabler-filled icon-tabler-file me-2 mb-1'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M12 2l.117 .007a1 1 0 0 1 .876 .876l.007 .117v4l.005 .15a2 2 0 0 0 1.838 1.844l.157 .006h4l.117 .007a1 1 0 0 1 .876 .876l.007 .117v9a3 3 0 0 1 -2.824 2.995l-.176 .005h-10a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-14a3 3 0 0 1 2.824 -2.995l.176 -.005h5z' />
                              <path d='M19 7h-4l-.001 -4.001z' />
                            </svg>
                          )}
                        </span>
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary'
                          onClick={item.is_folder ? () => onFolderClicked(item) : (null as any)}
                        >
                          {item.is_folder ? item.fName : item.name}
                        </a>
                      </div>
                    )}
                  </td>
                  <td>
                    {item.is_folder
                      ? formatFileSize(getFolderSize(item.id))
                      : formatFileSize(item.fileSize || 0)}
                  </td>

                  <td>
                    {' '}
                    {item.is_folder
                      ? formatDate(item.time_created)
                      : formatDate(item.time_created || item.timeCreated)}
                  </td>
                  <td>
                    <div className='d-flex justify-content-end'>
                      {isLoadingFolder === item.id && (
                        <div
                          className='spinner-border mt-4'
                          role='status'
                          style={{
                            color: '#44DBAF',
                            width: '1.5rem',
                            height: '1.5rem',
                          }}
                        >
                          <span className='visually-hidden'>Loading...</span>
                        </div>
                      )}
                      <div className='dropdown mx-2'>
                        <button
                          className='btn btn-secondary border-0 p-0 m-2'
                          type='button'
                          id='dropdownMenu2'
                          data-bs-toggle='dropdown'
                          aria-haspopup='true'
                          aria-expanded='false'
                        >
                          <img
                            src='/media/buttons/Options.svg'
                            alt='Options'
                            onClick={toggleDropdown}
                          />
                        </button>
                        <ul className='dropdown-menu ' aria-labelledby='dropdownMenu2'>
                          {!item.is_folder && item.contentType !=='application/zip' && (
                            <li>
                              <button
                                className='dropdown-item text-muted text-hover-primary'
                                type='button'
                                onClick={() => handleView(item)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-eye mx-1 mb-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0' />
                                  <path d='M21 12c-2.4 4 -5.4 6 -9 6c-3.6 0 -6.6 -2 -9 -6c2.4 -4 5.4 -6 9 -6c3.6 0 6.6 2 9 6' />
                                </svg>{' '}
                                View File
                              </button>
                            </li>
                          )}
                          <li>
                            {item.is_folder ? (
                              <button
                                className='dropdown-item text-muted text-hover-primary'
                                type='button'
                                onClick={() => handleDownloadZip(item.id, item.folder_name)}
                                // disabled={item.is_folder}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                                  <path d='M7 11l5 5l5 -5' />
                                  <path d='M12 4l0 12' />
                                </svg>{' '}
                                Download Folder
                              </button>
                            ) : (
                              <button
                                className='dropdown-item text-muted text-hover-primary'
                                type='button'
                                onClick={() => handleDownload(item)}
                                // disabled={item.is_folder}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  stroke='currentColor'
                                  stroke-width='2'
                                  stroke-linecap='round'
                                  stroke-linejoin='round'
                                  className='icon icon-tabler icons-tabler-outline icon-tabler-download mx-1 mb-1'
                                >
                                  <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                  <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
                                  <path d='M7 11l5 5l5 -5' />
                                  <path d='M12 4l0 12' />
                                </svg>{' '}
                                Download File
                              </button>
                            )}
                          </li>

                          <li>
                            <button
                              className='dropdown-item text-muted text-hover-primary'
                              type='button'
                              onClick={() => {
                                if (item.is_folder) {
                                  setEditingItemId(item.id || '')
                                } else {
                                  setEditingItemId(item.fileId || '')
                                }
                              }}
                              disabled={item.uid !== currentUser?.uid && currentUser?.role === 'Auditor'}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                stroke-width='2'
                                stroke-linecap='round'
                                stroke-linejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1 mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                                <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                                <path d='M16 5l3 3' />
                              </svg>{' '}
                              Rename
                            </button>
                          </li>

                          {/* <li>
                              <button
                                className='dropdown-item text-muted'
                                type='button'
                                onClick={handleShow2}
                              >
                                <div className='d-flex align-items-center text-muted fw-bold'>
                                  <i className='ki-duotone ki-some-files fs-2 me-2'>
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </i>
                                  <span className=''>Move to Folder</span>
                                </div>
                              </button>
                            </li> */}

                          <li>
                            <button
                              className='dropdown-item '
                              type='button'
                              key={item.id}
                              style={{color: '#F15363'}}
                              onClick={() => {
                                if (item.is_folder) {
                                  deleteFolder(item.id, item.folder_name)
                                } else {
                                  handleDeleteFile(item.fileId, item.name)
                                }
                              }}
                              disabled={item.uid !== currentUser?.uid && currentUser?.role === 'Auditor'}
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='18'
                                height='18'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='#F15363'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                              >
                                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                                <path d='M4 7l16 0' />
                                <path d='M10 11l0 6' />
                                <path d='M14 11l0 6' />
                                <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                                <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                              </svg>{' '}
                              Remove
                            </button>
                          </li>
                        </ul>
                      </div>

                      <Modal show={show2} onHide={handleClose2}>
                        <Modal.Header closeButton>
                          <Modal.Title>Move to Folder</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {data
                            .filter(
                              (item) =>
                                item.tenantId === currentUser?.tenantId &&
                                item.uid === currentUser?.uid
                            )
                            .map(
                              (item) =>
                                item.isFolder && (
                                  <>
                                    <div className='form-check form-check-sm form-check-custom form-check-solid d-flex align-items-center mb-5 fs-5 fw-bold '>
                                      <input
                                        className='form-check-input me-2'
                                        type='checkbox'
                                        value={index}
                                        name={item.name}
                                        // checked={item?.isChecked || false}
                                        onChange={() =>
                                          handleCheckboxMTFChange(item.id, item.folderName)
                                        }
                                        // onChange={()=>}
                                      />
                                      <span id='kt_file_manager_folder_icon' className='me-2'>
                                        <i
                                          className={`ki-duotone ki-${
                                            item.isFolder ? 'folder' : 'files'
                                          } fs-2x text-primary `}
                                        >
                                          <span className='path1'></span>
                                          <span className='path2'></span>
                                        </i>
                                      </span>
                                      <a
                                        href='#'
                                        className='text-gray-800 text-hover-primary '
                                        onClick={
                                          item.isFolder
                                            ? () => onFolderClicked(item)
                                            : (null as any)
                                        }
                                      >
                                        <span className='text-muted'>
                                          {' '}
                                          {item.isFolder ? item.fName : item.name}
                                        </span>
                                      </a>
                                    </div>
                                    <div className='separator separator-dotted  my-5'></div>
                                  </>
                                )
                            )}
                        </Modal.Body>
                        <Modal.Footer className='d-flex justify-content-center p-2'>
                          <Button variant='primary' onClick={() => handleMovetofolder(item)}>
                            Save
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              // <tbody>
              <tr style={{height: '100%'}}>
                {searchLength ? (
                  <td colSpan={10} className='text-center '>
                    <div className='m-20'>
                      <img src='/media/buttons/emptystate.svg' alt='' />
                      <div className='m-5 fs-5'>
                        <p style={{fontWeight: 'bold'}}>No Data Found</p>
                      </div>
                      <p className=''>
                        We couldn't find any data that match your search. Maybe try a different
                        search!
                      </p>
                    </div>
                  </td>
                ) : (
                  <td colSpan={10}>
                    <div
                      className='d-flex justify-content-center align-items-center text-center'
                      style={{height: '100%'}}
                    >
                      {showEmptyState ? (
                        // Show "No Data Added" message if showEmptyState is true
                        <tr>
                          <td colSpan={10}>
                            <div className='m-20'>
                              <img src='/media/buttons/emptystate.svg' alt='' />
                              <div className='m-5 fs-5'>
                                <p style={{fontWeight: 'bold'}}>No Data Added</p>
                              </div>
                              <p>
                                All the Data within your organization are required to address here.
                              </p>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        // Show loader if showEmptyState is false
                        <tr>
                          <td colSpan={10} className='text-center' style={{height: '500px'}}>
                            <DataRoomLoading />
                          </td>
                        </tr>
                      )}
                    </div>
                  </td>
                )}
              </tr>
              // </tbody>
            )}
          </tbody>
        </table>
        {/*end::Table*/}
      </div>
      <ToastContainer />
    </>
  )
}

export {Data_room}
