/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import {
  registerWithEmailAndPassword,
  registerWithEmailAndPassword2,
} from "../core/_requests";
import { Link } from "react-router-dom";
import { signInWithPopup, updateProfile } from "firebase/auth";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useAuth } from "../core/Auth";
import { v4 as uuid } from "uuid";
import { Helmet } from "react-helmet";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  writeBatch,
} from "@firebase/firestore";
import db, { auth1, googleProvider } from "../../../../services/firebase";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";

const initialValues = {
  fullname: "",
  email: "",
  password: "",
};

const registrationSchema = Yup.object().shape({
  fullname: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Full name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  // lastname: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Last name is required'),
  // activationCode: Yup.string()
  //   .matches(/^[a-zA-Z0-9]{10}$/, 'Activation code must be exactly 10 alphanumeric characters')
  //   .required('Activation code is required'),
  // referralId: Yup.string()
  //   .min(10, 'Minimum 10 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .notRequired(),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  // changepassword: Yup.string()
  //   .min(3, 'Minimum 3 symbols')
  //   .max(50, 'Maximum 50 symbols')
  //   .required('Password confirmation is required')
  //   .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  // acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
});
const generateUniqueTenantId = () => {
  return uuid();
};

const FREE_EMAIL_DOMAINS = [
  "gmail.com",
  "yahoo.com",
  "hotmail.com",
  "outlook.com",
  "aol.com",
  "icloud.com",
  "mail.com",
  "protonmail.com",
  "zoho.com",
  "yandex.com",
  "live.com",
  "inbox.com",
  "gmx.com",
  "yop.com",
];

const getPasswordStrength = (password: string): number => {
  let score = 0;

  // Length check
  if (password.length >= 8) score += 1;

  // Complexity checks
  if (/[A-Z]/.test(password)) score += 1; // Has uppercase
  if (/[a-z]/.test(password)) score += 1; // Has lowercase
  if (/[0-9]/.test(password)) score += 1; // Has number
  if (/[^A-Za-z0-9]/.test(password)) score += 1; // Has special char

  return score;
};

export function Register() {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser, googleSignIn } = useAuth();
  const [usersData, setUsersData] = useState<any[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState<string>("");

  useEffect(() => {
    async function fetchUsersData() {
      const usersCollectionRef = collection(db, "users");
      try {
        const usersSnapshot = await getDocs(usersCollectionRef);
        const usersList = usersSnapshot.docs.map((doc) => doc.data());
        setUsersData(usersList);
      } catch (error) {
        console.error("Error fetching users data:", error);
      }
    }

    fetchUsersData();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        const db = getFirestore();

        // Step 1: Register the new user
        const authCredential = await registerWithEmailAndPassword2(
          values.email,
          values.password,
          values.fullname
        );

        if (!authCredential.user || !authCredential.user.uid) {
          throw new Error("User is not authenticated");
        }

        // Check if email is already in use
        const isEmailUsed = usersData.some(
          (user) => user.email === values.email
        );
        if (isEmailUsed) {
          setStatus("Email is already in use.");
          setSubmitting(false);
          setLoading(false);
          return;
        }

        // Step 3: Retrieve token to confirm authentication
        const userToken = await authCredential.user.getIdToken();
        const tenantId = generateUniqueTenantId();

        // Step 4: Update profile with first and last name
        await updateProfile(authCredential.user, {
          displayName: `${values.fullname}`,
        });

        // Step 5: Set up auth and user models
        const authModel = {
          api_token: userToken,
          email: authCredential.user.email,
          uid: authCredential.user.uid,
          userName: `${values.fullname}`,
          password: values.password,
          tenantId: tenantId,
        };

        const userModel = {
          uid: authModel.uid,
          userName: authModel.userName,
          api_token: authModel.api_token,
          password: authModel.password,
          email: authModel.email,
          tenantId: tenantId,
          role: "Owner",
        };

        // Step 6: Upload user model to Firestore
        await setDoc(doc(db, "users", authCredential.user.uid), userModel);

        // Save auth model and set current user
        saveAuth(authModel);
        setCurrentUser(userModel);

        // Create a batch write for setting up tenant data
        const formattedDate = new Date().toISOString().split("T")[0];
        const batch = writeBatch(db);

        // Step 7: Setup tenant data
        const tenantRef = doc(db, "tenants", tenantId);
        batch.set(tenantRef, {});

        // if (associatedFrameworks[0] === 'GDPR') {
        //   // Copy default controls, tests, and policies to the new tenant (GDPR specific)
        //   const controlsSnapshot = await getDocs(collection(db, 'gdpr_controls'));
        //   controlsSnapshot.forEach((controlDoc) => {
        //     batch.set(doc(collection(tenantRef, 'controls'), controlDoc.id), controlDoc.data());
        //   });
        // } else {
        // Copy default controls, tests, and policies to the new tenant (Non-GDPR)
        const controlsSnapshot = await getDocs(
          collection(db, "default_controls")
        );
        controlsSnapshot.forEach((controlDoc) => {
          batch.set(
            doc(collection(tenantRef, "controls"), controlDoc.id),
            controlDoc.data()
          );
        });
        // }

        const testsSnapshot = await getDocs(collection(db, "default_tests"));
        testsSnapshot.forEach((testDoc) => {
          batch.set(
            doc(collection(tenantRef, "tests"), testDoc.id),
            testDoc.data()
          );
        });

        const policySnapshot = await getDocs(
          collection(db, "policy_templates")
        );
        policySnapshot.forEach((policyDoc) => {
          batch.set(
            doc(collection(tenantRef, "policies"), policyDoc.id),
            policyDoc.data()
          );
        });

        // Add default group and personnel checklist
        const groupRef = doc(collection(tenantRef, "personnel_group"));
        batch.set(groupRef, {
          groupName: "[Default]",
          checklist: "[Default]",
          createdOn: formattedDate,
          createdBy: authModel.userName,
          tenantId: tenantId,
        });

        const checklistRef = doc(collection(tenantRef, "personnel_checklist"));
        batch.set(checklistRef, {
          checklist_name: "[Default]",
          policies: [],
          createdon: formattedDate,
          createdBy: authModel.userName,
          tenantId: tenantId,
        });

        const personnelRef = doc(
          collection(tenantRef, "employees"),
          authModel.uid
        );
        batch.set(personnelRef, {
          created_at: new Date().toISOString(),
          tenantId: tenantId,
          uid: authModel.uid,
          full_name: authModel.userName,
          group: "[Default]",
          role: "Owner",
          start_date: formattedDate,
          email: authModel.email,
        });

        const activeFrameworksRef = doc(
          collection(tenantRef, "active_frameworks")
        );
        batch.set(activeFrameworksRef, { frameworks: "" });

        const integrationsRef = doc(
          collection(tenantRef, "active_integrations")
        );
        batch.set(integrationsRef, {
          integrations: ["AWS", "Asana", "GitLab", "Sentry"],
        });

        await batch.commit();
      } catch (error) {
        saveAuth(undefined);
        setStatus("The registration details are incorrect");
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const isValidEmailFormat = emailRegex.test(formik.values.email);

    if (formik.values.email && isValidEmailFormat) {
      const domain = formik.values.email.split("@")[1].toLowerCase();
      const isPersonalEmail = FREE_EMAIL_DOMAINS.includes(domain);

      if (isPersonalEmail) {
        setEmailError("Please use your work email address");
        setShowPassword(false);
      } else {
        setEmailError("");
        setShowPassword(true);
      }
    } else {
      setShowPassword(false);
      setEmailError(formik.errors.email || "");
    }
  }, [formik.values.email, formik.errors.email]);

  const signInWithGoogle = async () => {
    try {
      const authCredential = await signInWithPopup(auth1, googleProvider);
      const user = authCredential.user;

      if (!user) {
        throw new Error("Google authentication failed");
      }

      // Check if user already exists in Firestore
      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      let userModel;
      if (!userDocSnap.exists()) {
        // If user doesn't exist, create a new user profile
        const tenantId = generateUniqueTenantId(); // Reuse your existing tenant ID generation function

        userModel = {
          uid: user.uid,
          userName: user.displayName || "",
          email: user.email || "",
          first_name: user.displayName?.split(" ")[0] || "",
          last_name: user.displayName?.split(" ")[1] || "",
          tenantId: tenantId,
          role: "Owner",
          photo_url: user.photoURL || "",
          provider: "google",
          api_token: await user.getIdToken(),
        };

        // Save user to Firestore
        await setDoc(userDocRef, userModel);

        // Setup basic tenant data
        const batch = writeBatch(db);
        const tenantRef = doc(db, "tenants", tenantId);

        // Create basic tenant structure
        batch.set(tenantRef, {});

        const controlsSnapshot = await getDocs(
          collection(db, "default_controls")
        );
        controlsSnapshot.forEach((controlDoc) => {
          batch.set(
            doc(collection(tenantRef, "controls"), controlDoc.id),
            controlDoc.data()
          );
        });

        const testsSnapshot = await getDocs(collection(db, "default_tests"));
        testsSnapshot.forEach((testDoc) => {
          batch.set(
            doc(collection(tenantRef, "tests"), testDoc.id),
            testDoc.data()
          );
        });

        const policySnapshot = await getDocs(
          collection(db, "policy_templates")
        );
        policySnapshot.forEach((policyDoc) => {
          batch.set(
            doc(collection(tenantRef, "policies"), policyDoc.id),
            policyDoc.data()
          );
        });

        const formattedDate = new Date().toISOString().split("T")[0];

        const groupRef = doc(collection(tenantRef, "personnel_group"));
        batch.set(groupRef, {
          groupName: "[Default]",
          checklist: "[Default]",
          createdOn: formattedDate,
          createdBy: user.displayName,
          tenantId: tenantId,
        });

        const checklistRef = doc(collection(tenantRef, "personnel_checklist"));
        batch.set(checklistRef, {
          checklist_name: "[Default]",
          policies: [],
          createdon: formattedDate,
          createdBy: user.displayName,
          tenantId: tenantId,
        });

        const personnelRef = doc(collection(tenantRef, "employees"), user.uid);
        batch.set(personnelRef, {
          created_at: new Date().toISOString(),
          tenantId: tenantId,
          uid: user.uid,
          full_name: user.displayName || "",
          group: "[Default]",
          role: "Owner",
          start_date: formattedDate,
          email: user.email || "",
        });

        // Commit batch write
        await batch.commit();
      } else {
        // User exists, retrieve existing user model
        userModel = userDocSnap.data();
      }

      // Save auth and set current user
      const authModel = {
        api_token: await user.getIdToken(),
        email: user.email || "",
        uid: user.uid,
        userName: user.displayName || "",
        tenantId: userModel.tenantId,
        photo_url: user.photoURL || "",
      };

      saveAuth(authModel);
      setCurrentUser(userModel);

      return userModel;
    } catch (error) {
      console.error("Google Sign-In Error:", error);
      saveAuth(undefined);
      throw error;
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const user = await signInWithGoogle();
      // Handle successful sign-in (e.g., redirect to dashboard)
    } catch (error) {
      // Handle sign-in error
      console.error("Google Sign-In Failed", error);
    }
  };

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  useEffect(() => {
    if (formik.status) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [formik.status]);

  return (
    <>
      {" "}
      <Helmet>
        <title>SecureSlate - Sign up</title>
      </Helmet>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className="text-center mb-11">
          {/* begin::Title */}
          <h1 className="text-dark fw-bolder mb-3">Sign Up</h1>
          {/* end::Title */}

          <div className="text-gray-500 fw-semibold fs-6">
            {" "}
            Safeguarding Your Data with Robust Security Solutions.
          </div>
        </div>
        {/* end::Heading */}

        {/* begin::Login options */}
        <div className="justify-content-center  row g-3 mb-9">
          <div className="col-md-9">
            <button
              onClick={handleGoogleSignIn}
              type="button"
              className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-dark bg-state-light flex-center text-nowrap w-100"
            >
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}
                className="h-15px me-3"
              />
              Sign in with Google
            </button>
            {errorMessage && (
              <div className="error-message mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {errorMessage}
                </div>
              </div>
            )}
          </div>
          {/* end::Col */}

          {/* begin::Col */}
          {/* <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div> */}
        </div>
        {/* end::Login options */}

        <div className="separator separator-content my-14">
          <span className="w-125px text-gray-500 fw-semibold fs-7">
            Or with email
          </span>
        </div>

        {formik.status && (
          <div className="mb-lg-15 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}

        {/* begin::Form group Firstname */}
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            Full name
          </label>
          <input
            placeholder="Full name"
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("fullname")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.fullname && formik.errors.fullname,
              },
              {
                "is-valid": formik.touched.fullname && !formik.errors.fullname,
              }
            )}
          />
          {formik.touched.fullname && formik.errors.fullname && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.fullname}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Email */}
        <div className="fv-row mb-8">
          <label className="form-label fw-bolder text-dark fs-6">
            Work email
          </label>
          <input
            placeholder="Work email"
            type="email"
            autoComplete="off"
            {...formik.getFieldProps("email")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid":
                  (formik.touched.email && formik.errors.email) || emailError,
              },
              {
                "is-valid":
                  formik.touched.email && !formik.errors.email && !emailError,
              }
            )}
          />
          {((formik.touched.email && formik.errors.email) || emailError) && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{emailError || formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Password */}
        {showPassword && (
          <div className="fv-row mb-8" data-kt-password-meter="true">
            <div className="mb-1">
              <label className="form-label fw-bolder text-dark fs-6">
                Password
              </label>
              <div className="position-relative mb-3">
                <input
                  type="password"
                  placeholder="Password"
                  autoComplete="off"
                  {...formik.getFieldProps("password")}
                  className={clsx(
                    "form-control bg-transparent",
                    {
                      "is-invalid":
                        formik.touched.password && formik.errors.password,
                    },
                    {
                      "is-valid":
                        formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.password}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* begin::Meter */}
              <div className="d-flex align-items-center mb-3">
                {[...Array(5)].map((_, index) => (
                  <div
                    key={index}
                    className={clsx(
                      "flex-grow-1 bg-secondary rounded h-5px me-2",
                      {
                        "bg-success":
                          index < getPasswordStrength(formik.values.password),
                      }
                    )}
                  ></div>
                ))}
              </div>
              {/* <div className="text-muted">
                Password strength:{" "}
                {formik.values.password
                  ? ["Very Weak", "Weak", "Medium", "Strong", "Very Strong"][
                      getPasswordStrength(formik.values.password) - 1
                    ] || "Very Weak"
                  : ""}
              </div> */}
              {/* <div className='text-muted mt-2'>
    Requirements:
    <ul className='mb-0'>
      <li>At least 8 characters</li>
      <li>At least one uppercase letter</li>
      <li>At least one lowercase letter</li>
      <li>At least one number</li>
      <li>At least one special character</li>
    </ul>
  </div> */}

              {/* end::Meter */}
            </div>
            {/* <div className="text-muted">
              Use 8 or more characters with a mix of letters, numbers & symbols.
            </div> */}
          </div>
        )}
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-4"
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className="indicator-label">Sign up</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <span className="fs-6 fw-semibold">
            Already have an account?
            <Link to="/auth/login">{""} Login</Link>
          </span>
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}
