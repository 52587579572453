/* eslint-disable no-loop-func */
import {getFirestore, doc, collection, getDocs, addDoc, updateDoc} from 'firebase/firestore'
import {getStorage, ref, getDownloadURL} from 'firebase/storage'
import JSZip from 'jszip'
import {uploadBytes} from 'firebase/storage'

export const ExportZipTest = async (
  currentUser,
  frameworks,
  auditFolderName,
  folderId,
  formattedDate,
  auditID
) => {
  if (!currentUser?.tenantId) {
    throw new Error('No authenticated user or tenant ID found')
  }

  const frameworksArray = Array.isArray(frameworks) ? frameworks : [frameworks]
  const db = getFirestore()
  const storage = getStorage()
  const tenantId = currentUser.tenantId
  const tenantRef = doc(db, 'tenants', tenantId)
  const PolicyRef = collection(tenantRef, 'tests')

  try {
    const querySnapshot = await getDocs(PolicyRef)
    const data = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      framework: doc.data().framework,
      files: doc.data().files,
      ...doc.data(),
    }))

    // Process each framework separately
    for (const framework of frameworksArray) {
      const zip = new JSZip()
      let frameworkFileCount = 0

      // Filter items for current framework with null check
      const filteredItems = data.filter((test) => {
        if (!test.framework) return false
        const testFrameworks = test.framework
          .toString()
          .split(',')
          .map((fw) => fw.trim())
        return testFrameworks.includes(framework)
      })

      // Process files for this framework
      for (const test of filteredItems) {
        const customFiles = test.files || []
        await Promise.all(
          customFiles.map(async (file) => {
            if (!file.url) return

            try {
              // Get signed URL with retry logic
              const signedUrl = await getSignedUrlWithRetry(file.url, file.name, currentUser)
              if (!signedUrl) return

              // Download file with timeout and retry
              const fileBlob = await downloadFileWithRetry(signedUrl)
              if (!fileBlob || fileBlob.size === 0) return

              // Add to zip with proper file name handling
              const fileName = sanitizeFileName(file.name || `file_${frameworkFileCount + 1}`)
              zip.file(fileName, fileBlob)

              frameworkFileCount++
            } catch (error) {
              console.error(`Error processing file ${file.name}:`, error)
            }
          })
        )
      }

      try {
        await createAndUploadZip({
          zip,
          framework,
          formattedDate,
          tenantId,
          auditFolderName,
          storage,
          currentUser,
          folderId,
          tenantRef,
          auditID,
          frameworkFileCount,
        })
      } catch (error) {
        console.error(`Error processing framework ${framework}:`, error)
      }
    }
  } catch (error) {
    throw new Error(`Failed to export zip: ${error}`)
  }
}

// Helper functions
export const getSignedUrlWithRetry = async (
  fileUrl: string,
  fileName: string,
  currentUser,
  maxRetries = 3
): Promise<string> => {
  let lastError: Error | null = null

  for (let i = 0; i < maxRetries; i++) {
    try {
      // Make the request to get the signed URL
      const response = await fetch(
        'https://us-central1-slate-134a0.cloudfunctions.net/generateSignedUrl',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            filePath: `tenants/${currentUser?.tenantId}/tests/test_evidence/${fileName}`,
          }),
        }
      )

      // Check if the response is OK
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}))
        throw new Error(
          `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorData)}`
        )
      }

      // Parse the response data
      const data: {signedUrl?: string} = await response.json()
      if (!data.signedUrl) {
        throw new Error('No signed URL in response')
      }

      // Successfully got the signed URL; return it immediately
      return data.signedUrl
    } catch (error) {
      // Store the last error
      lastError = error instanceof Error ? error : new Error(String(error))

      // If this is the last attempt, throw the error
      if (i === maxRetries - 1) {
        throw new Error(
          `Failed to get signed URL after ${maxRetries} attempts. Last error: ${lastError.message}`
        )
      }

      // Wait before retrying, with exponential backoff
      const waitTime = Math.min(1000 * Math.pow(2, i), 10000)
      await new Promise((resolve) => setTimeout(resolve, waitTime))
    }
  }

  // If we exit the loop without success, throw an error
  throw new Error(
    `Failed to get signed URL after ${maxRetries} attempts. Last error: ${lastError?.message}`
  )
}

const downloadFileWithRetry = async (url, maxRetries = 3) => {
  const timeout = 30000 // 30 seconds timeout

  for (let i = 0; i < maxRetries; i++) {
    try {
      const controller = new AbortController()
      const timeoutId = setTimeout(() => controller.abort(), timeout)

      const response = await fetch(url, {signal: controller.signal})
      clearTimeout(timeoutId)

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
      const blob = await response.blob()
      if (blob.size === 0) throw new Error('Empty file received')

      return blob // Exit the function if the download is successful
    } catch (error) {
      if (i === maxRetries - 1) throw error
      await new Promise((resolve) => setTimeout(resolve, 1000 * (i + 1)))
    }
  }
}

const sanitizeFileName = (fileName) => {
  return fileName
    .replace(/[^a-zA-Z0-9.-]/g, '_')
    .replace(/_{2,}/g, '_')
    .trim()
}

const createAndUploadZip = async ({
  zip,
  framework,
  formattedDate,
  tenantId,
  auditFolderName,
  storage,
  currentUser,
  folderId,
  tenantRef,
  auditID,
  frameworkFileCount,
}) => {
  const zipBlob = await zip.generateAsync({
    type: 'blob',
    compression: 'DEFLATE',
    compressionOptions: {level: 6},
  })

  const zipFileName = `${formattedDate} - tests evidence - ${framework}.zip`

  // Download locally
  // const downloadLink = document.createElement('a')
  // downloadLink.href = URL.createObjectURL(zipBlob)
  // downloadLink.download = zipFileName
  // document.body.appendChild(downloadLink)
  // downloadLink.click()
  // document.body.removeChild(downloadLink)
  // URL.revokeObjectURL(downloadLink.href)

  // Upload to Firebase
  const storageRef = ref(storage, `tenants/${tenantId}/dataroom/${auditFolderName}/${zipFileName}`)

  await uploadBytes(storageRef, zipBlob)
  const downloadURL = await getDownloadURL(storageRef)

  // Create dataroom entry
  const dataRoomRef = collection(tenantRef, 'dataroom')
  const fileDoc = {
    createdAt: new Date().toISOString(),
    name: zipFileName,
    contentType: 'application/zip',
    downloadUrl: downloadURL,
    fileSize: zipBlob.size,
    subFolder: storageRef.fullPath,
    time_created: formattedDate,
    tenantId,
    uid: currentUser?.uid,
    is_folder: false,
    uploadedBy: currentUser?.userName,
    parentFolder: folderId,
    framework,
    fileId: '',
  }

  const docRef = await addDoc(dataRoomRef, fileDoc)
  await updateDoc(docRef, {fileId: docRef.id})
  await updateAuditDocument(currentUser, auditID, frameworkFileCount)
  return {success: true}
}

const updateAuditDocument = async (currentUser, auditId, totalLength) => {
  if (!currentUser?.tenantId || !auditId) return

  const db = getFirestore()
  const tenantRef = doc(db, 'tenants', currentUser.tenantId)
  const policyRef = doc(tenantRef, 'audits', auditId)

  await updateDoc(policyRef, {TotalTestFiles: totalLength})
}
