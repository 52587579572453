/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore'
import db from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {VendorListToolbar} from './components/header/VendorListToolbar'
import Swal from 'sweetalert2'
import {Helmet} from 'react-helmet'
import {UpdateVendor} from './components/UpdateVendor'
import {PolicyListLoading} from './components/loading/PolicyListLoading'
import {toast} from 'react-toastify'

type Props = {
  className: string
}

const VendorRisk: React.FC<Props> = ({className}) => {
  const {currentUser} = useAuth()
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [vendor, setVendor] = useState<any[]>([])
  const [selectedCategory, setSelectedCategory] = useState('')
  const [searchLength, setSearchLength] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [vendorID, setVendorID] = useState('')
  const [checkedItems, setCheckedItems] = useState<any[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)

  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 1000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    setFilteredItems(vendor)
  }, [vendor])

  useEffect(() => {
    getVendor()
  }, [])

  const getVendor = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'vendors')

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            archived: doc.data().archived,
          }))
          .filter((vendor) => !vendor.archived) // Filter out archived vendors
        setVendor(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const deleteHandler = async (Data) => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${Data.vendor_name}`,
        text: `Are you sure you want to remove ${Data.vendor_name} vendor ? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        // await riskServices.deleteRisk(riskData.id)
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'vendors')
        const docRef = doc(policyRef, Data.id)
        await deleteDoc(docRef)
        getVendor()

        toast.success(`${Data.vendor_name} vendor has been deleted successfully!`, {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        const currentDate = new Date() // No need to import the Firestore module
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1 // Note: The month is zero-based
        const day = currentDate.getDate()

        const todaysDate = month + '/' + day + '/' + year

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} has deleted vendor '${Data.vendor_name}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          action: 'Vendor deleted',
          target: 'Vendor Risk',
          username: currentUser?.userName,
          date: todaysDate,
        })

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${Data.vendor_name}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the vendor.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }

  const handleApplyFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const category = e.target.value
    if (category === 'All') {
      handleResetFilter()
    } else {
      const filtered = vendor.filter((v) => v.risk_level.includes(category))
      setFilteredItems(filtered)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)

    // Assuming policy is your original data array
    if (searchTerm === '') {
      // If the search term is empty, reset to the original policies
      setFilteredItems(vendor)
      setSearchLength(false)
    } else {
      const filteredItems = vendor.filter((user) =>
        user.vendor_name.toLowerCase().includes(searchTerm)
      )
      setFilteredItems(filteredItems)
      setSearchLength(filteredItems.length === 0)
    }
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }

  const handleResetFilter = () => {
    // setSelectedCategory('All')
    setFilteredItems(vendor)
  }

  const handleOpenModal = (id) => {
    setIsModalOpen(true)
    setVendorID(id)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleArchive = async (item) => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const vendorsRef = collection(tenantRef, 'vendors')
      const docRef = doc(vendorsRef, item.id)

      await updateDoc(docRef, {
        archived: true,
      })

      toast.success(`${item.vendor_name} vendor has been archived successfully`, {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })

      const currentDate = new Date() // No need to import the Firestore module
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth() + 1 // Note: The month is zero-based
      const day = currentDate.getDate()

      const todaysDate = month + '/' + day + '/' + year

      const activitiesRef = collection(tenantRef, 'activities')
      await addDoc(activitiesRef, {
        message: `${currentUser?.userName} has archived vendor '${item.vendorName}'`,
        timestamp: serverTimestamp(),
        tenantId: currentUser?.tenantId,
        uid: currentUser?.uid,
        action: 'Vendor archieved',
        target: 'Vendor Risk',
        username: currentUser?.userName,
        date: todaysDate,
      })

      // Update local state to reflect the change
      setVendor((prevVendors) =>
        prevVendors.map((v) => (v.id === item.id ? {...v, archived: true} : v))
      )

      // Show a success message
      Swal.fire({
        icon: 'success',
        title: 'Archived!',
        text: `${item.vendor_name} vendor has been archived.`,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        buttonsStyling: false,
      })
    } catch (error) {
      console.error('Error archiving vendor:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while archiving the vendor.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  const handleSelectAllChange = (e) => {
    const isChecked = e.target.checked
    setSelectAll(isChecked)
    setCheckedItems(isChecked ? filteredItems.map((item) => item.id) : [])
  }

  const handleCheckChange = (e) => {
    const {checked, value} = e.target
    setCheckedItems((prev) => {
      const newCheckedItems = checked ? [...prev, value] : prev.filter((item) => item !== value)

      // Update selectAll state based on whether all items are checked
      setSelectAll(newCheckedItems.length === filteredItems.length)

      return newCheckedItems
    })
  }

  const handleDeselectClick = () => {
    setCheckedItems([])
    setSelectAll(false)
  }

  const handleMultipleDelete = async () => {
    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove Selected Vendors`,
        text: `Are you sure you want to remove ${checkedItems.length} selected vendors? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'vendors')

        // Delete all selected vendors
        const deletePromises = checkedItems.map(async (vendorId) => {
          const docRef = doc(policyRef, vendorId)
          await deleteDoc(docRef)
        })

        await Promise.all(deletePromises)

        // Refresh the vendor list
        getVendor()

        toast.success('Selected vendors has been deleted successfully!', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        // Clear the selection
        setCheckedItems([])
        setSelectAll(false)

        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: `${checkedItems.length} vendors have been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the vendors.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    }
  }

  return (
    <>
      {/* begin::Header */}
      <Helmet>
        <title>SecureSlate | Vendor Risk</title>
      </Helmet>

      {/* end::Header */}
      <div className='card-header border-0 pt-6 h-100'>
        <div className='card-title'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control  w-250px ps-14'
              placeholder='Search..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            {/* <div className='fs-6 fw-semibold w-30'>Risk Level:</div> */}
            {checkedItems.length > 0 ? (
              <>
                {checkedItems.length > 0 ? (
                  <div className='  border-end pe-4 text-center' style={{color: '#A1A5B7'}}>
                    <span>
                      <p className='pt-4'>{checkedItems.length} Selected</p>
                    </span>
                  </div>
                ) : null}
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div className='mx-5' style={{cursor: 'pointer'}} onClick={handleDeselectClick}>
                    <img src='/media/buttons/Deselect.svg' alt='Deselect' />
                  </div>

                  <button
                    className='btn btn-sm fs-6'
                    onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#148363')
                    }
                    onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
                      (e.currentTarget.style.backgroundColor = '#1CBB8D')
                    }
                    onClick={handleMultipleDelete}
                    style={{
                      backgroundColor: '#1CBB8D',
                      color: '#fff',
                      width: '140px',
                      height: '40px',
                      borderRadius: '4px',
                      left: '615px',
                      top: '20px',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    {' '}
                    Delete vendors
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className='d-flex align-items-center justify-content-end '>
                  <span className='fs-6 fw-semibold me-2'>RiskLevel:</span>
                  <select
                    className='form-select select2-hidden-accessible me-4'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Status'
                    data-kt-ecommerce-product-filter='status'
                    data-select2-id='select2-data-9-zism'
                    tabIndex={-1}
                    aria-hidden='true'
                    data-kt-initialized='1'
                    style={{height: '40px'}}
                    value={selectedCategory}
                    // style={{width:'70%'}}
                    onChange={(e) => {
                      setSelectedCategory(e.target.value)
                      if (e.target.value === 'All') {
                        handleResetFilter()
                      } else {
                        handleApplyFilter(e)
                      }
                    }}
                  >
                    <option onClick={handleResetFilter} selected>
                      All
                    </option>
                    <option value='Low'>Low</option>
                    <option value='Medium'>Medium</option>
                    <option value='High'>High</option>
                  </select>
                </div>
                <VendorListToolbar />
              </>
            )}
          </div>
        </div>
      </div>
      {/* begin::Body */}
      <div className='table-responsive py-3 px-10' style={{minHeight: '50vh'}}>
        {/* begin::Table */}
        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='fw-bold fs-7' style={{color: '#5A5A5A'}}>
              <th className='w-25px'>
                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    checked={selectAll}
                    data-kt-check='true'
                    data-kt-check-target='.widget-9-check'
                    onChange={handleSelectAllChange}
                  />
                </div>
              </th>
              <th className='min-w-300px'>VENDOR NAME</th>
              <th className='min-w-100px'>RISK LEVEL</th>
              <th className='min-w-150px'>DUE DILIGENCE</th>
              <th className='min-w-100px'>CATEGORY</th>
              <th className='min-w-150px'>OWNER</th>
              <th className='min-w-50px'></th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <UpdateVendor id={vendorID} isOpen={isModalOpen} onClose={handleCloseModal} />
          <tbody>
            {filteredItems && filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <tr>
                  <td>
                    <div className='form-check form-check-sm form-check-custom form-check-solid'>
                      <input
                        className='form-check-input widget-9-check'
                        type='checkbox'
                        value={item.id}
                        checked={checkedItems.includes(item.id)}
                        onChange={handleCheckChange}
                      />
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-30px me-3'>
                        {/* <img src={toAbsoluteUrl(item.logo[0]?.url || item.logo[0]?.logoUrl)} alt='' loading='lazy' /> */}
                        {item.logo && item.logo.length > 0 ? (
                          <img src={toAbsoluteUrl(item.logo[0]?.url)} alt='' loading='lazy' />
                        ) : (
                          <img src={toAbsoluteUrl(item.logoUrl)} alt='' loading='lazy' />
                        )}
                      </div>
                      <div className='d-flex justify-content-start flex-column'>
                        <a
                          href={`/vendor-risk/details/${item.id}`}
                          className='text-dark fw-bold text-hover-primary fs-6'
                        >
                          {item.vendor_name}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    {item.risk_level ? (
                      <a
                        href='#'
                        className={`badge fs-7 ${
                          item.risk_level === 'High'
                            ? 'badge-danger'
                            : item.risk_level === 'Medium'
                            ? 'badge-warning'
                            : 'badge-primary'
                        }`}
                        style={{
                          color:
                            item.risk_level === 'High'
                              ? '#EE3043'
                              : item.risk_level === 'Medium'
                              ? '#FFA800'
                              : '#1CBB8D',
                          background:
                            item.risk_level === 'High'
                              ? '#FFF5F8'
                              : item.risk_level === 'Medium'
                              ? '#FFF8DD'
                              : '#E8F8F4',
                        }}
                      >
                        {item.risk_level}
                      </a>
                    ) : (
                      '--'
                    )}
                  </td>

                  <td>
                    <div className='d-flex flex-column w-100 me-2'>
                      <div className='d-flex flex-stack mb-2'>
                        <div className='clearfix bshadow0 pbs fw-semibold'>
                          <span
                            className={`badge fs-7 ${
                              !item.due_diligence_status ||
                              item.due_diligence_status === 'Incomplete'
                                ? 'badge-danger'
                                : 'badge-primary'
                            }`}
                            style={{
                              color:
                                !item.due_diligence_status ||
                                item.due_diligence_status === 'Incomplete'
                                  ? '#EE3043'
                                  : '#1CBB8D',
                              background:
                                !item.due_diligence_status ||
                                item.due_diligence_status === 'Incomplete'
                                  ? '#FFF5F8'
                                  : '#E8F8F4',
                            }}
                          >
                            {item.due_diligence_status ? item.due_diligence_status : 'Incomplete'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {item.category ? item.category : '--'}
                        </a>
                      </div>
                    </div>
                  </td>

                  <td>
                    <div className='d-flex align-items-center'>
                      {item.owner ? (
                        <>
                          <div className='symbol symbol-35px me-4'>
                            <span
                              className='symbol-label fs-7 fw-bold'
                              style={{backgroundColor: '#DAF9EF'}}
                            >
                              {(() => {
                                const nameParts = item.owner.split(' ')
                                if (nameParts.length > 1) {
                                  return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[1]
                                    .charAt(0)
                                    .toUpperCase()}`
                                } else {
                                  return `${nameParts[0].charAt(0).toUpperCase()}${nameParts[0]
                                    .charAt(1)
                                    .toUpperCase()}`
                                }
                              })()}
                            </span>
                          </div>
                          <a href='#' className='text-dark fw-bold text-hover-primary fs-7'>
                            {item.owner}
                          </a>
                        </>
                      ) : (
                        <span>--</span>
                      )}
                    </div>
                  </td>
                  <td className=''>
                    <div className='dropdown mx-2'>
                      <button
                        className='btn btn-secondary border-0 p-0 m-2'
                        type='button'
                        id='dropdownMenu2'
                        data-bs-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                      >
                        <img
                          src='/media/buttons/Options.svg'
                          alt='Options'
                          onClick={toggleDropdown}
                        />
                      </button>
                      <ul className='dropdown-menu' aria-labelledby='dropdownMenu2'>
                        <li>
                          <button
                            className='dropdown-item text-muted text-hover-primary'
                            type='button'
                            onClick={() => handleOpenModal(item.id)}
                            disabled={currentUser?.role === 'Auditor'}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-edit mx-1 mb-1'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1' />
                              <path d='M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z' />
                              <path d='M16 5l3 3' />
                            </svg>{' '}
                            Edit Vendor
                          </button>
                        </li>

                        <li>
                          <button
                            className='dropdown-item text-muted text-hover-primary'
                            type='button'
                            onClick={() => handleArchive(item)}
                            disabled={currentUser?.role === 'Auditor'}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-archive  mx-1 mb-1'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z' />
                              <path d='M5 8v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-10' />
                              <path d='M10 12l4 0' />
                            </svg>{' '}
                            Archive Vendor
                          </button>
                        </li>

                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            style={{color: '#F15363'}}
                            onClick={(e) => deleteHandler(item)}
                            disabled={currentUser?.role === 'Auditor'}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='18'
                              height='18'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='#F15363'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='icon icon-tabler icons-tabler-outline icon-tabler-trash mx-1 mb-1'
                            >
                              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                              <path d='M4 7l16 0' />
                              <path d='M10 11l0 6' />
                              <path d='M14 11l0 6' />
                              <path d='M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12' />
                              <path d='M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3' />
                            </svg>{' '}
                            Delete Vendor
                          </button>
                        </li>
                      </ul>
                    </div>
                    {/* <PolicyListToolbar policyDataId={policyData.id} /> */}
                  </td>
                </tr>
              ))
            ) : (
              <>
                <tr style={{height: '100%'}}>
                  {!searchLength ? (
                    <td colSpan={10}>
                      <div
                        className='d-flex justify-content-center align-items-center text-center'
                        style={{height: '100%'}}
                      >
                        <div className='m-20'>
                          <img src={toAbsoluteUrl('/media/emptystate/riskstate.svg')} alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No vendor found!</p>
                          </div>
                          <p>
                            We couldn't find any vendor that match your search. Maybe try a
                            different search!
                          </p>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td colSpan={6}>
                      <div
                        className='d-flex justify-content-center align-items-center text-center'
                        style={{height: '100%'}}
                      >
                        {!showEmptyState ? (
                          <div className='m-20'>
                            <PolicyListLoading />
                          </div>
                        ) : (
                          <div className='m-20'>
                            <img src={toAbsoluteUrl('/media/emptystate/riskstate.svg')} alt='' />
                            <div className='m-4 fs-5'>
                              <p style={{fontWeight: 'bold'}}>No vendors risk level matched</p>
                            </div>
                            <p>
                              We couldn't find any vendor that match your filter/search. Maybe try
                              a different search!
                            </p>
                          </div>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              </>
            )}
          </tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>

      {/* end::Body */}
    </>
  )
}

export default VendorRisk
